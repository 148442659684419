import styled from 'styled-components'

import media from 'utils/media'

export default styled.section`
   padding-top: 50px;
   padding-bottom: 60px;
   background-color: ${({ theme, background }) => (background ? theme.colors.lightGreyBg : '')};

   ${media.greaterThan('md')`
    padding-top: 118px;
    padding-bottom: 10px;
  `};
`
