import styled from 'styled-components'

import media from 'utils/media'

import arrowDown from '../../assets/svg/arrow-down.svg'

export default styled.div`
   position: relative;
   text-align: ${({ textPosition }) => (textPosition === 'center' ? 'center' : 'left')};
   color: ${({ theme }) => theme.colors.white};

   ${media.greaterThan('md')`
    width: ${({ textPosition }) => (textPosition === 'center' ? '760px' : '100%')};
  `};

   ${media.greaterThan('1680px')`
    width: ${({ textPosition }) => (textPosition === 'center' ? '960px' : '80%')};
  `};

   h1,
   h2 {
      margin-bottom: 0;
      color: ${({ theme }) => theme.colors.white};
   }

   h1 {
      ${media.lessThan('md')`
      letter-spacing: 0.2;
    `};

      ${media.greaterThan('lg')`
      font-size: ${({ largeText }) => (largeText ? '72px' : '55px')};
    `};
   }

   h1 + h2 {
      line-height: 47px;

      ${media.lessThan('lg')`
      font-size: 20px;
    `};
   }

   span {
      margin-bottom: 32px;
      display: block;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-size: 20px;
      line-height: 2.67;

      ${media.greaterThan('lg')`
      font-size: 24px;
    `};
   }

   h2 + a {
      display: none;

      ${media.greaterThan('lg')`
      display: block;
      float: right;
      font-size: 12px;
      line-height: 2.67;
      letter-spacing: 1.5;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.textColor};
      cursor: pointer;

      &:after {
        content: '';
        width: 32px;
        height: 32px;
        display: block;
        margin: 0 auto;
        background-image: url(${arrowDown});
        background-position: center;
        background-repeat: no-repeat;
      }
    `};
   }
`
