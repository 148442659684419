import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { graphql } from 'gatsby'

import Markdown from 'components/Markdown'
import { Container, Row, Column } from 'components/Grid'
import SmallText from 'components/SmallText'

import { StyledSection, StyledHero, StyledFilters } from './styles'
import CategoryFilter from './CategoryFilter'
import SearchFilter from './SearchFilter'
import Project from './Project'

export default function ProjectsList({ portfolioHeading, projects = [], categoriesLabel, searchLabel, smallText }) {
   const [activeFilter, setActiveFilter] = useState('ALL')
   const [filteredProjects, setFilteredProjects] = useState([])
   const [detailedProjects, setDetailedProjects] = useState([])

   const categories = useMemo(() => {
      const allCategories = projects.reduce((p, { projectCategory }) => {
         return projectCategory ? [...p, ...projectCategory] : p
      }, [])

      return Array.from(new Set(allCategories))
   }, [projects])

   const handleChangeDetailedProjects = useCallback(
      (projectId) => {
         if (typeof window !== 'undefined' && window.innerWidth < 768) {
            if (detailedProjects.includes(projectId)) {
               const newDetailedProjects = detailedProjects.filter((el) => el !== projectId)
               setDetailedProjects(newDetailedProjects)
            } else {
               setDetailedProjects((prevState) => [...prevState, projectId])
            }
         }
      },
      [detailedProjects]
   )

   function handleSearch(value) {
      if (value) {
         const newFilteredProjects = projects.filter((project) =>
            project.name.toLowerCase().includes(value.toLowerCase())
         )
         setFilteredProjects(newFilteredProjects)
      } else {
         setFilteredProjects(projects)
      }
   }

   useEffect(() => {
      if (activeFilter === 'ALL') {
         setFilteredProjects(projects)
      } else {
         const newFilteredProjects = projects.filter(
            (item) => item.projectCategory && item.projectCategory.some((el) => el === activeFilter)
         )
         setFilteredProjects(newFilteredProjects)
      }
   }, [activeFilter])

   return (
      <StyledSection>
         <Container>
            <Row>
               <Column width={[1]}>
                  <StyledHero>{portfolioHeading && <Markdown>{portfolioHeading.markdown}</Markdown>}</StyledHero>
               </Column>
               <Column width={[1]}>
                  <StyledFilters>
                     <SearchFilter onChange={handleSearch} placeholder={searchLabel} />
                     <CategoryFilter
                        options={categories}
                        placeholder={categoriesLabel}
                        value={activeFilter}
                        onChange={(e) => setActiveFilter(e.target.value)}
                     />
                  </StyledFilters>
               </Column>
               <Column width={[1]}>
                  {filteredProjects.map((project) => (
                     <Project
                        key={project.id}
                        {...project}
                        collapsed={!detailedProjects.includes(project.id)}
                        onChangeCollapsed={() => handleChangeDetailedProjects(project.id)}
                     />
                  ))}
               </Column>
               {smallText && (
                  <Column width={[1]} mt={[64]}>
                     <SmallText content={smallText.markdown} />
                  </Column>
               )}
            </Row>
         </Container>
      </StyledSection>
   )
}

export const query = graphql`
   fragment ProjectsList on ContentfulPartialPortfolioProjects {
      id
      portfolioHeading {
         markdown: portfolioHeading
      }
      categoriesLabel
      searchLabel
      smallText {
         markdown: smallText
      }
      learnMoreLabel
      projects {
         id
         name
         projectCategory
         projectLogo {
            title
            file {
               url
            }
         }
         websiteLink {
            title
            type
            url
         }
         twitterLink {
            title
            type
            url
         }
         postLink
         shortDescription
         projectContent {
            markdown: projectContent
         }
         aquiredBy {
            url
            type
            title
         }
      }
   }
`
