import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import isEmpty from 'lodash/isEmpty'

import Markdown from 'components/Markdown'
import { Container, Row } from 'components/Grid'

import Section from './Section'
import Images from './Images'
import Content from './Content'

const ContentTwoImages = ({ heading, content, image1, image2 }) => {
   return (
      <Section>
         <Container>
            <Row>
               <Images width={[1, 1, 4 / 12, 5 / 12]} ml={[0, 0, `${(1 / 12) * 100}%`]}>
                  {!isEmpty(image1) && <GatsbyImage image={image1.gatsbyImageData} />}
                  {!isEmpty(image2) && <GatsbyImage image={image2.gatsbyImageData} />}
               </Images>
               <Content width={[1, 1, 7 / 12, 6 / 12]}>
                  <h2>{heading}</h2>
                  {!isEmpty(content) && !isEmpty(content.markdown) && <Markdown>{content.markdown}</Markdown>}
               </Content>
            </Row>
         </Container>
      </Section>
   )
}

export const query = graphql`
   fragment ContentTwoImagesData on ContentfulPartialContentTwoImages {
      id
      heading
      content {
         markdown: content
      }
      image1 {
         gatsbyImageData(width: 1000)
      }
      image2 {
         gatsbyImageData(width: 1000)
      }
   }
`

export default ContentTwoImages
