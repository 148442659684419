import styled from 'styled-components'

import media from 'utils/media'

export const StyledSection = styled.section`
   padding-top: 24px;
   padding-bottom: 190px;

   ${media.greaterThan('md')`
      padding-bottom: 96px;
   `};
`

export const StyledSmallTitle = styled.h2`
   margin-bottom: 0;
   font-weight: 500;
   font-size: 38px;
   line-height: 47px;
   letter-spacing: -0.5px;
   padding-bottom: 8px;
   border-bottom: 2px solid #4a4a4a;
   color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.dark)};
`

export const StyledLabel = styled.span`
   font-weight: 700;
   font-size: 18px;
   line-height: 27px;
   letter-spacing: 0.41px;
`

export const StyledButton = styled.button`
   padding: 0;
   margin: 0;
   border: none;
   background-color: transparent;
   color: ${({ theme }) => theme.colors.hotCinnamon};
   font-size: 18px;
   line-height: 27px;
   letter-spacing: 0.41px;
   text-transform: uppercase;
   cursor: pointer;
   transition: ${({ theme }) => theme.transitions.main};

   & + & {
      margin-left: 16px;
   }

   &:hover {
      font-weight: bold;
   }
`

export const StyledLogosList = styled.div`
   margin: 36px 0;

   ${media.greaterThan('md')`
      margin: 48px 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
   `};

   ${media.greaterThan('lg')`
      margin: 32px 0 48px;
      gap: 34px 130px;
   `};
`

export const StyledLogo = styled.div`
   padding: 16px;
   border: 1px solid ${({ theme }) => theme.colors.grey_4};

   & + & {
      margin-top: 36px;

      ${media.greaterThan('md')`
         margin-top: 0;
      `};
   }
`

export const StyledLogoInfo = styled.div`
   span {
      display: block;
      margin-top: 8px;
      margin-bottom: 16px;

      ${media.greaterThan('lg')`
         display: inline-block;
         margin-bottom: 0;
         margin-right: 16px;
      `};
   }
`

export const StyledHeadshotsList = styled.div`
   margin: 36px 0 0;

   ${media.greaterThan('md')`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
   `};

   ${media.greaterThan('lg')`
      grid-template-columns: 1fr 1fr 1fr;
      gap: 48px 40px;
   `};

   ${media.greaterThan('xl')`
      margin: 48px 0 0;
   `};
`

export const StyledHeadshot = styled.div`
   padding: 16px;
   border: 1px solid ${({ theme }) => theme.colors.grey_4};

   ${media.greaterThan('md')`
      padding: 32px 40px;
   `};

   ${media.greaterThan('lg')`
      padding: 32px;
   `};

   & + & {
      margin-top: 16px;

      ${media.greaterThan('md')`
         margin-top: 0;
      `};
   }
`

export const StyledHeadshotInfo = styled.div`
   margin-top: 8px;
`
