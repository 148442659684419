import styled from 'styled-components'

import { Column } from 'components/Grid'

import media from 'utils/media'

export default styled(Column)`
   text-align: ${({ textCenter }) => (textCenter ? 'center' : 'left')};
   position: relative;
   margin: 0 auto 46px;

   ${media.greaterThan('md')`
    margin-bottom: 60px;  
  `};

   ${media.greaterThan('lg')`
    margin-bottom: 80px;  
  `};

   div {
      ${media.greaterThan('xl')`
      max-width: 780px;
      margin: 0 auto;
    `};

      h2 {
         margin-bottom: 0;
         letter-spacing: -0.4px;

         ${media.greaterThan('md')`
        letter-spacing: -0.5px;
      `};
      }

      span {
         display: block;
         text-transform: uppercase;
         margin-bottom: 34px;
         font-size: 17px;
         line-height: 2.67;
         letter-spacing: 1.5px;

         ${media.greaterThan('lg')`
        margin-bottom: 19px;
        font-size: 22px;
      `};
      }

      h2 + p,
      h2 + p span {
         margin-bottom: 0;
      }

      h2 + p {
         margin-top: 33px;

         ${media.greaterThan('md')`
        margin-top: 40px;
      `};
      }

      p {
         ${media.greaterThan('md')`
        font-size: 18px;
      `};
      }

      p + p {
         margin-top: 32px;
      }

      hr {
         width: 100%;
         margin: 70px auto 0;
         background-color: ${({ theme }) => theme.colors.hrBg};
         height: 1px;

         ${media.greaterThan('md')`
        width: 53%;
        margin-top: 54px;
      `};
      }
   }
`
