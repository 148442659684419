import styled from 'styled-components'

import media from 'utils/media'

export default styled.section`
   padding: 53px 0 30px;
   position: relative;

   ${media.greaterThan('md')`
    padding: 40px 0 100px;

    &:after {
      content: '';
      position: absolute;
      width: 50%;
      right: 0;
      background-color: ${({ theme }) => theme.colors.lightGreyBg};
      z-index: 0;
      top: 40px;
      bottom: 100px;
      height: calc(100% - 140px);
    }

  `};

   .container {
      ${media.greaterThan('md')`
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 50%;
        height: 100%;
        left: 10px;
        top: 0;
        background-color: ${({ theme }) => theme.colors.lightGreyBg};
        z-index: 0;
      }
    `};
   }
`
