import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import isEmpty from 'lodash/isEmpty'

import Markdown from 'components/Markdown'
import { Row, Column } from 'components/Grid'

import { Section, Content, Image, StyledContainer } from './styles'

const OurFirm = ({ heading, content, image }) => {
   return (
      <Section>
         <StyledContainer>
            <Row>
               <Column>
                  <h2>{heading}</h2>
               </Column>
            </Row>
            <Row>
               <Content width={[1, 1, 1, 7 / 12]}>
                  {!isEmpty(content) && !isEmpty(content.markdown) && <Markdown>{content.markdown}</Markdown>}
               </Content>
               <Image width={[1, 1, 1, 4 / 12]} ml={[0, 0, 0, `${(1 / 12) * 100}%`]}>
                  {!isEmpty(image) && <GatsbyImage image={image.gatsbyImageData} alt={image.description} />}
               </Image>
            </Row>
         </StyledContainer>
      </Section>
   )
}

export const query = graphql`
   fragment OurFirmData on ContentfulPartialOurFirm {
      id
      heading
      content {
         markdown: content
      }
      image {
         description
         gatsbyImageData(width: 1200)
      }
   }
`

export default OurFirm
