import styled from 'styled-components'
import { Column } from 'components/Grid'
import media from 'utils/media'

export const Content = styled(Column)`
   position: relative;

   & > div {
      text-align: center;
      width: 100%;
   }

   .hero {
      margin: 70px 0 60px;
   }

   h1 {
      font-size: 48px;
      line-height: 1;
      margin-bottom: 28px;
      color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.dark)};
   }

   p {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.textColor};

      &.lead {
         font-size: 24px;
         line-height: 36px;
         color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.dark)};
      }
   }

   p + p {
      margin-top: 28px;

      ${media.greaterThan('sm')`
         margin-top: 40px;
      `};
   }

   span {
      font-size: 11px;
      line-height: 2;
      letter-spacing: 1.4px;

      &.label {
         display: block;
         margin-bottom: 16px;
         font-size: 24px;
         line-height: 36px;
         font-weight: 700;
         letter-spacing: 1.5px;
         text-transform: uppercase;
         color: ${({ theme }) => theme.colors.hotCinnamon};
      }

      ${media.greaterThan('lg')`
         font-size: 12px;
         line-height: 2.67;
         letter-spacing: 1.5px;
      `};
   }

   a {
      margin: 26px auto;

      &.btn {
         display: block;
         max-width: 200px;
      }
   }

   span + a {
      margin-top: 51px;

      ${media.greaterThan('md')`
         margin-top: 17px;
      `};

      ${media.greaterThan('lg')`
         margin-top: 37px;
      `};
   }

   iframe {
      border: none;
      margin-bottom: 0;
      display: block;
   }

   .ctaText {
      font-size: 14px;

      ${media.greaterThan('md')`
         font-size: 17px;
      `};
   }
`
