import styled from 'styled-components'

import media from 'utils/media'

export default styled.section`
   padding-top: 55px;
   padding-bottom: 30px;

   ${media.greaterThan('md')`
    padding-top: 146px;
    padding-bottom: 80px;
  `};

   & > div {
      ${media.greaterThan('md')`  
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -85px;
        left: 10px;
        right: 10px;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.border};
      }  
    `};

      ${media.greaterThan('lg')`
      &:after {
        width: 64%;
        margin: 0 auto;
      }
    `};
   }

   h4 {
      color: ${({ theme }) => theme.colors.header};
      text-align: center;
      margin-bottom: 47px;

      ${media.greaterThan('md')`
      margin-bottom: 52px;
    `};
   }
`
