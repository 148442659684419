import styled from 'styled-components'

import media from 'utils/media'

export default styled.div`
   height: 400px;
   width: 100%;
   transform: translateY(0);
   z-index: 2;

   .gatsby-image-wrapper {
      height: 100%;
   }

   ${media.greaterThan('md')`
      height: 526px;
      width: 50%;
      max-width: 570px;
      min-width: 315px;
      box-shadow: -26px 26px 34px 0 rgba(0, 0, 0, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-104px, 87px);
  `};

   ${media.greaterThan('lg')`
      transform: translate(-150px, 108px);
      height: 722px;
  `};
`
