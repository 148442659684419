import React from 'react'

// imports of Components
import ContentList from 'components/ContentList'
import ImageContent from 'components/ImageContent'
import Hero from 'components/Hero'
import LongText from 'components/LongText'
import PageIntro from 'components/PageIntro'

// imports of Partials
import OurFirm from 'partials/about/OurFirm'
import Team from 'partials/about/Team'
import ContentTwoImages from 'partials/thesis/ContentTwoImages'
// import SummitForm from 'partials/summit/SummitForm'
import Sponsors from 'partials/summit/Sponsors'
// import Videos from 'partials/summit/Videos'
// import Projects from 'partials/portfolio/Projects'
import ProjectsList from 'partials/portfolio/ProjectsList'
import NotFound from 'partials/404/404Data'
import ContactUs from 'partials/contact/ContactUs'
import PressHero from 'partials/press/PressHero'
import PressResources from 'partials/press/PressResources'
import MarketplaceSection from 'partials/marketplace/MarketplaceSection'

const ComponentNotFound = ({ __typename }) => {
   return (
      <p>
         Component <strong>{__typename}</strong> Not Found
      </p>
   )
}

const body = {
   ContentfulComponentContentList: ContentList,
   ContentfulComponentImageContent: ImageContent,
   ContentfulComponentHero: Hero,
   ContentfulComponentLongText: LongText,
   ContentfulComponentPageIntro: PageIntro,
   ContentfulPartialOurFirm: OurFirm,
   ContentfulPartialTeam: Team,
   ContentfulPartialContentTwoImages: ContentTwoImages,
   // ContentfulPartialSummitForm: SummitForm,
   ContentfulPartialSponsors: Sponsors,
   // ContentfulPartialVideos: Videos,
   ContentfulPartialPortfolioProjects: ProjectsList,
   ContentfulPartial404Data: NotFound,
   ContentfulPartialContactUs: ContactUs,
   ContentfulPartialPressHero: PressHero,
   ContentfulPartialPressResources: PressResources,
   ContentfulPartialMarketplace: MarketplaceSection,
   NotFound: ComponentNotFound,
}

export default function CfConnector(props) {
   const SelectedComponent = typeof body[props.__typename] === 'function' ? body[props.__typename] : body.NotFound
   return <SelectedComponent {...props} />
}
