import React from 'react'
import { graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'

import Link from 'components/Link'
import FontAwesome from 'components/FontAwesome'
import { Container, Row, Column } from 'components/Grid'
import {
   StyledSection,
   StyledTitle,
   StyledContent,
   StyledContactPerson,
   StyledPerson,
   StyledSocials,
   StyledLabel,
   StyledPersonTitle,
} from './styles'

export default function PressHero({ heading, content, contactPersonTitle, contactPerson }) {
   const isContactPersonData = !!contactPerson
   return (
      <StyledSection>
         <Container>
            <Row justifyContent="space-between">
               <Column width={[1, 1, 1 / 2]}>
                  <StyledTitle>{heading}</StyledTitle>
                  {content?.markdown && (
                     <StyledContent>
                        <Markdown>{content.markdown}</Markdown>
                     </StyledContent>
                  )}
               </Column>
               <Column width={[1, 1, 1 / 2, '390px']} alignSelf={['flex-start', 'flex-start', 'flex-end', 'center']}>
                  <StyledContactPerson>
                     <StyledPersonTitle>{contactPersonTitle}</StyledPersonTitle>
                     {isContactPersonData && (
                        <div>
                           <StyledPerson>
                              <img src={contactPerson.photo?.url} alt="Author photo" />
                              <div>
                                 <strong>{contactPerson.name}</strong>
                                 <StyledLabel>{contactPerson.position}</StyledLabel>
                              </div>
                           </StyledPerson>
                           <StyledSocials>
                              {Array.isArray(contactPerson.socials) &&
                                 contactPerson.socials.map((social) => (
                                    <Link
                                       key={social.name}
                                       type="external"
                                       url={social.url}
                                       aria-label={social.name}
                                       target="_blank"
                                    >
                                       <div>
                                          <FontAwesome icon={social.name} />
                                       </div>
                                       <StyledLabel>{social.label}</StyledLabel>
                                    </Link>
                                 ))}
                           </StyledSocials>
                        </div>
                     )}
                  </StyledContactPerson>
               </Column>
            </Row>
         </Container>
      </StyledSection>
   )
}

export const query = graphql`
   fragment PressHero on ContentfulPartialPressHero {
      id
      heading
      content {
         markdown: content
      }
      contactPersonTitle
      contactPerson {
         photo {
            url
            gatsbyImageData
         }
         name
         position
         socials {
            id
            url
            name
            label
         }
      }
   }
`
