import styled from 'styled-components'

import media from 'utils/media'

export default styled.section`
   height: 100%;
   min-height: 440px;
   position: relative;
   padding: 0 33px;
   padding-bottom: ${({ textPosition }) => (textPosition === 'center' ? '0' : '39px')};
   display: flex;
   justify-content: ${({ textPosition }) => (textPosition === 'center' ? 'center' : 'flex-start')};
   align-items: ${({ textPosition }) => (textPosition === 'center' ? 'center' : 'flex-end')};

   ${media.greaterThan('md')`
    min-height: 670px;
    padding: 0 87px;
    padding-bottom:  ${({ textPosition }) => (textPosition === 'center' ? '0' : '57px')};
  `};

   ${media.greaterThan('lg')`
    min-height: 870px;
    padding: 0 120px;
    padding-bottom:  ${({ textPosition }) => (textPosition === 'center' ? '0' : '75px')};
  `};
`
