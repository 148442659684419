import styled from 'styled-components'
import media from 'utils/media'

export const StyledSection = styled.section`
   padding-top: 30px;
   padding-bottom: 20px;

   ${media.greaterThan('md')`
      padding-top: 54px;
      padding-bottom: 24px;
   `};

   ${media.greaterThan('lg')`
      padding-top: 78px;
   `};
`

export const StyledTitle = styled.h1`
   margin-bottom: 24px;
   color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.dark)};
   letter-spacing: -0.5px;
   font-weight: 500;
   font-size: 38px;
   line-height: 47px;

   ${media.greaterThan('md')`
      font-size: 50px;
      line-height: 66px;
      margin-bottom: 36px;
   `};
`

export const StyledContent = styled.div`
   margin-bottom: 36px;

   ${media.greaterThan('md')`
      margin-bottom: 0px;
   `};

   p {
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.4px;
      margin-bottom: 30px;

      &:last-of-type {
         margin-bottom: 0;
      }
   }
`

export const StyledContactPerson = styled.div`
   padding: 24px;
   background-color: ${({ theme }) => (theme.darkMode ? '#353535' : theme.colors.grey_5)};

   ${media.greaterThan('md')`
      width: 100%;
   `};

   ${media.greaterThan('lg')`
      padding: 24px 36px 48px;
   `};
`

export const StyledPersonTitle = styled.span`
   display: block;
   padding-bottom: 6px;
   margin-bottom: 24px;
   font-weight: 700;
   font-size: 24px;
   line-height: 37px;
   letter-spacing: 1.5px;
   color: ${({ theme }) => theme.colors.hotCinnamon};
   text-transform: uppercase;
   border-bottom: 2px solid #4a4a4a;

   ${media.greaterThan('md')`
      font-size: 32px;
      line-height: 49px;
   `};
`

export const StyledLabel = styled.span`
   display: block;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.36px;
`

export const StyledPerson = styled.div`
   width: 100%;
   margin-bottom: 24px;
   display: flex;
   align-items: center;
   justify-content: space-between;

   img {
      width: 70px;
      height: 70px;
      margin-bottom: 0;
      border-radius: 100%;
      overflow: hidden;
   }

   div {
      flex: 0 1 70%;

      strong {
         color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.textColor)};
         font-size: 18px;
         line-height: 27px;
         letter-spacing: 0.4125px;
      }
   }
`

export const StyledSocials = styled.div`
   a {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      transition: ${({ theme }) => theme.transitions.main};

      div {
         width: 29px;
         height: 29px;
         margin-right: 15px;
         flex: 0 0 29px;
         display: flex;
         justify-content: center;
         align-items: center;
         border-radius: 100%;
         overflow: hidden;
         background-color: ${({ theme }) => theme.colors.socialIconBg};
         color: ${({ theme }) => theme.colors.socialIcon};
         transition: ${({ theme }) => theme.transitions.main};
      }

      &:last-of-type {
         margin-bottom: 0;
      }

      &:hover {
         color: ${({ theme }) => theme.colors.hotCinnamon};
         font-weight: bold;

         div {
            background-color: ${({ theme }) => theme.colors.hotCinnamon};
         }
      }
   }
`
