import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import isEmpty from 'lodash/isEmpty'

import Markdown from 'components/Markdown'
import { Container, Row } from 'components/Grid'

import Section from './Section'
import Content from './Content'
import Image from './Image'

const ImageContent = ({ content, image }) => {
   return (
      <Section>
         <Container className="container">
            {!isEmpty(image) && (
               <Image>
                  <GatsbyImage image={image.gatsbyImageData} alt={image.description} />
               </Image>
            )}
            <Row>
               <Content width={[1, 1, 10 / 12]} ml={[0, 0, `${(2 / 12) * 100}%`]}>
                  {!isEmpty(content) && !isEmpty(content.markdown) && <Markdown>{content.markdown}</Markdown>}
               </Content>
            </Row>
         </Container>
      </Section>
   )
}

export const query = graphql`
   fragment ImageContentData on ContentfulComponentImageContent {
      id
      content {
         markdown: content
      }
      image {
         description
         gatsbyImageData
      }
   }
`

export default ImageContent
