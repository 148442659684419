import styled from 'styled-components'

import media from 'utils/media'

export const Section = styled.section`
   padding-top: ${({ withoutPadding }) => (withoutPadding ? 0 : '28px')};
   padding-bottom: ${({ withoutPadding }) => (withoutPadding ? 0 : '90px')};

   ${media.greaterThan('md')`
    padding-top: ${({ withoutPadding }) => (withoutPadding ? 0 : '120px')};
    padding-bottom: ${({ withoutPadding }) => (withoutPadding ? 0 : '160px')};
  `};
`
