import styled from 'styled-components'

export default styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: ${({ theme }) => theme.colors.grey};

   .gatsby-image-wrapper {
      height: 100%;
   }
`
