import styled from 'styled-components'

import media from 'utils/media'

import { Column } from 'components/Grid'

const Images = styled(Column)`
   display: flex;
   justify-content: space-between;
   margin-bottom: 35px;

   ${media.greaterThan('576px')`
    transform: translateX(10px);
  `};

   ${media.lessThan('md')`
    flex-direction: column;
  `};

   ${media.greaterThan('md')`
    transform: translateX(0);
    margin-bottom: 0;
    order: 2;
    display: block;
  `};

   .gatsby-image-wrapper {
      width: 100%;
      min-width: 176px;
      margin-bottom: 10px;

      ${media.greaterThan('md')`
      width: 324px;
      min-height: 243px;
      margin-bottom: 0;
      width: 80%;
    `};

      ${media.greaterThan('lg')`
      width: auto;
      min-width: 100%;
    `};

      ${media.greaterThan('xl')`
      min-width: 380px;
      margin-left: auto;
      width: 80%;
    `};

      & + .gatsby-image-wrapper {
         ${media.greaterThan('md')`
        margin-top: 30px;
        margin-left: 0;
      `};

         ${media.greaterThan('xl')`
        margin-left: auto;
      `};
      }
   }
`

export default Images
