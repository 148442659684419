import React from 'react'
import { graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import Markdown from 'components/Markdown'
import { Container, Row, Column } from 'components/Grid'

import Section from './Section'
import TeamMember from './TeamMember'

const Team = ({ heading, content, bioShowLabel, bioHideLabel, members }) => {
   return (
      <Section id="team">
         <Container>
            <Row>
               <Column width={[1, 1, 1, 7 / 12]} className="title">
                  <h2>{heading}</h2>
                  {!isEmpty(content) && !isEmpty(content.markdown) && <Markdown>{content.markdown}</Markdown>}
               </Column>
               <Column>
                  {!isEmpty(members) &&
                     members.map((member) => (
                        <TeamMember key={member.id} {...member} showLabel={bioShowLabel} hideLabel={bioHideLabel} />
                     ))}
               </Column>
            </Row>
         </Container>
      </Section>
   )
}

export const query = graphql`
   fragment TeamData on ContentfulPartialTeam {
      id
      heading
      content {
         markdown: content
      }
      bioShowLabel
      bioHideLabel
      members {
         id
         name
         position
         photo {
            gatsbyImageData(width: 700)
         }
         bio {
            markdown: bio
         }
         socials {
            id
            url
            name
         }
      }
   }
`

export default Team
