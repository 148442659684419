import React from 'react'
import styled from 'styled-components'

import media from 'utils/media'

import Markdown from 'components/Markdown'
import Link from 'components/Link'

export default function Project({
   name,
   projectLogo,
   twitterLink,
   websiteLink,
   postLink,
   projectContent,
   collapsed,
   onChangeCollapsed,
   aquiredBy,
}) {
   const postLinkType = postLink ? (postLink.includes('http') ? 'external' : 'internal') : null

   return (
      <Wrapper collapsed={collapsed} onClick={onChangeCollapsed}>
         <StyledName>
            <div>
               <p>{name}</p>
               {aquiredBy && (
                  <StyledAquiredBy>
                     Acquired by{' '}
                     <a target="blank" href={aquiredBy.url} rel="noreferrer noopener">
                        {aquiredBy.title}
                     </a>
                  </StyledAquiredBy>
               )}
            </div>
         </StyledName>
         <StyledLinks>
            {twitterLink && (
               <Link url={twitterLink.url} type={twitterLink.type}>
                  {twitterLink.title || 'Twitter'}
               </Link>
            )}
            {websiteLink && (
               <Link url={websiteLink.url} type={websiteLink.type}>
                  {websiteLink.title || 'Website'}
               </Link>
            )}
            {postLinkType && (
               <Link url={postLink} type={postLinkType}>
                  Read The Post
               </Link>
            )}
         </StyledLinks>
         <StyledDescription>
            {projectContent.markdown && <Markdown>{projectContent.markdown}</Markdown>}
         </StyledDescription>
         {projectLogo && (
            <StyledLogo>
               <img src={projectLogo.file.url} alt={projectLogo.title} />
            </StyledLogo>
         )}
      </Wrapper>
   )
}

const StyledName = styled.div`
   height: 102px;
   order: 1;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 60%;

   ${media.greaterThan('md')`
      height: 100%;
      width: 30%;
      padding: 16px 0 16px 32px;
      justify-content: flex-start;
   `};

   ${media.greaterThan('lg')`
      width: 20%;
      order: 2;
   `};

   ${media.greaterThan('xl')`
      width: 18%;
   `};

   div {
      text-align: center;

      ${media.greaterThan('md')`
         text-align: left;
      `};
   }

   p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.3375px;
      color: ${({ theme }) => theme.colors.header};

      ${media.greaterThan('md')`
         font-size: 24px;
         line-height: 37px;
      `};
   }
`

const StyledAquiredBy = styled.span`
   display: block;
   font-size: 12px;
   line-height: 18px;
   letter-spacing: 0.34px;

   a {
      display: inline;
      padding: 0;
      outline: none;
      color: ${({ theme }) => theme.colors.hotCinnamon};
      font-weight: bold;
      font-size: inherit;
      line-height: inherit;
   }
`

const StyledLinks = styled.div`
   width: 40%;

   ${media.greaterThan('md')`
      width: 15%;
   `};

   ${media.greaterThan('lg')`
      width: 13%;
   `};

   ${media.greaterThan('xl')`
      width: 22%;
      display: flex;
   `};

   a {
      display: block;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.34px;

      &:hover {
         color: ${({ theme }) => theme.colors.hotCinnamon};
      }
   }
`
const StyledLogo = styled.div`
   width: 40%;
   height: 102px;
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-bottom: ${({ theme }) => `2px solid ${theme.colors.hotCinnamon}`};

   ${media.greaterThan('md')`
      display: none;
      border-bottom: none;
   `};

   ${media.greaterThan('lg')`
      width: 20%;
      height: 100%;
      padding: 16px;
      display: flex;
   `};

   ${media.greaterThan('xl')`
      width: 15%;
   `};

   img {
      display: block;
      max-height: 100%;
      max-width: 100%;
      margin: 0;

      ${media.greaterThan('lg')`
         max-height: 100px;
      `};
   }
`

const StyledDescription = styled.div`
   width: 60%;
   padding: 16px;
   order: 3;

   ${media.greaterThan('md')`
      width: 55%;
      padding: 16px 64px;
      order: 2;
   `};

   ${media.greaterThan('lg')`
      width: 47%;
      padding: 16px 40px;
      order: 3;
   `};

   ${media.greaterThan('xl')`
      width: 45%;
   `};

   p {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.3375px;
      color: ${({ theme }) => theme.colors.header};
   }
`

const Wrapper = styled.div`
   width: 100%;
   height: ${({ collapsed }) => (collapsed ? '102px' : 'auto')};
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   overflow: hidden;
   border: ${({ collapsed, theme }) =>
      collapsed ? `1px solid ${theme.colors.grey_4}` : `2px solid ${theme.colors.hotCinnamon}`};
   position: relative;

   ${media.greaterThan('md')`
      height: auto;
      border: 1px solid ${({ theme }) => theme.colors.grey_4};
      transition: 0.4s cubic-bezier(0, 0.89, 0.44, 1);

      &:hover {
         box-shadow: ${({ theme }) =>
            theme.darkMode ? '0px 8px 36px rgba(239, 238, 238, 0.50)' : '0px 8px 36px rgba(0, 0, 0, 0.25)'};
      }
   `};

   ${media.greaterThan('lg')`
      &:after {
         content: '';
         width: 1px;
         height: 100%;
         background-color: ${({ theme }) => theme.colors.grey_4};
         position: absolute;
         left: 20%;
         top: 0;
         z-index: 2;

      }
   `};

   ${media.greaterThan('xl')`
      &:after {
         left: 15%;
      }
   `};

   ${StyledName} {
      border-right: ${({ collapsed, theme }) =>
         collapsed ? `1px solid ${theme.colors.grey_4}` : `2px solid ${theme.colors.hotCinnamon}`};
      border-bottom: ${({ collapsed, theme }) => (collapsed ? 'none' : `2px solid ${theme.colors.hotCinnamon}`)};

      ${media.greaterThan('md')`
         border-right: none;
         border-bottom: none;
      `};
   }

   ${StyledLinks} {
      padding: ${({ collapsed }) => (collapsed ? '10px' : '20px')};
      order: ${({ collapsed }) => (collapsed ? 2 : 4)};
      align-self: ${({ collapsed }) => (collapsed ? 'center' : 'flex-start')};

      ${media.greaterThan('md')`
         padding: 16px 0;
         order: 3;
         align-self: center;
      `};

      ${media.greaterThan('lg')`
         order: 4;
      `};

      a + a {
         margin-top: ${({ collapsed }) => (collapsed ? '4px' : '16px')};

         ${media.greaterThan('md')`
            margin-top: 8px;
         `};

         ${media.greaterThan('xl')`
            margin-top: 0px;
            margin-left: 24px;
         `};
      }
   }

   ${StyledLogo} {
      order: ${({ collapsed }) => (collapsed ? 4 : 2)};

      ${media.greaterThan('lg')`
         order: 1;
      `};
   }
`
