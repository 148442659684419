export default {
   languages: [
      {
         lang: 'en-US',
         label: 'English',
         disabled: false,
      },
      {
         lang: 'zh',
         label: '普通話',
         disabled: false,
      },
      {
         lang: 'ko',
         label: '한국어',
         disabled: false,
      },
      {
         lang: 'ja',
         label: '日本語',
         disabled: false,
      },
   ],
   projectCategory: {
      LAYER1: 'Layer 1',
      LAYER2: 'Layer 2',
      INFRASTRUCTURE: '基礎設施',
      IDENTITY: 'IDENTITY',
      PRIVACY: '隱私',
      ENTERPRISE: '企業',
      MOBILE: '移動',
   },
   socials: {
      facebook: 'Facebook的',
      twitter: '推特',
      linkedin: 'LinkedIn',
      email: '電子郵件',
      reddit: '書籤交易',
      telegram: '電報',
   },
   hamburgerMenu: {
      label: '菜單',
   },
}
