import styled from 'styled-components'

import { Column } from 'components/Grid'
import media from 'utils/media'

export default styled(Column)`
   text-align: ${({ textCenter }) => (textCenter ? 'center' : 'left')};
   margin-bottom: 36px;

   ${media.greaterThan('md')`
    margin-bottom: 65px;
  `};

   ${media.greaterThan('lg')`
    margin-bottom: 80px;
  `};

   &:last-of-type {
      margin-bottom: 0;
   }

   h2,
   h5 {
      margin-bottom: 23px;
   }

   h4,
   h5 {
      color: ${({ theme }) => theme.colors.header};
   }

   h2 {
      letter-spacing: -0.5px;

      ${media.greaterThan('lg')`
      margin-bottom: 33px;
    `};
   }

   h4 {
      font-size: 22px;

      ${media.greaterThan('md')`
      height: 56px;
    `};
   }

   h5 {
      font-size: 20px;

      ${media.greaterThan('md')`
      font-size: 25px;
      margin-bottom: 33px;
    `};
   }

   hr {
      width: 19%;
      margin: 0 auto;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.hrBg};

      ${media.greaterThan('md')`
      width: 100%;
    `};

      ${media.greaterThan('lg')`
      height: 2px;
    `};
   }

   p {
      line-height: 1.5;
      margin-top: 23px;

      ${media.greaterThan('md')`
      font-size: 16px;
    `};

      ${media.greaterThan('lg')`
      margin-top: 38px;
    `};
   }

   hr + p {
      font-size: 18px;

      ${media.greaterThan('md')`
      font-size: 16px;
    `};

      ${media.greaterThan('lg')`
      font-size: 18px;
    `};
   }

   h4 + p {
      margin-top: 0;
      line-height: 28px;
   }

   ul li {
      letter-spacing: -0.2px;
      margin-bottom: 23px;
      position: relative;
      display: flex;
      align-items: center;

      ${media.greaterThan('sm')`
      letter-spacing: -0.5px;
    `};

      &:last-of-type {
         margin-bottom: 0;
      }

      & > img {
         border-radius: 50%;
         width: 60px;
         height: 60px;
         margin-right: 18px;
      }
   }
`
