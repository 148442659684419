import React from 'react'
import styled from 'styled-components'

import media from 'utils/media'

import FontAwesome from 'components/FontAwesome'
import Button from 'components/Button'
import iconDown from 'assets/svg/chevron-down.svg'
// Input Field

export const InputField = React.forwardRef(({ id, label, required, error, ...rest }, ref) => {
   return (
      <StyledInputWrapper>
         <StyledLabel htmlFor={id}>{`${label}${required ? '*' : ''}`}</StyledLabel>
         <input type="text" {...rest} id={id} ref={ref} aria-invalid={error?.message ? 'true' : 'false'} />
         {error && <StyledErrorMsg role="alert">{error.message}</StyledErrorMsg>}
      </StyledInputWrapper>
   )
})

const StyledLabel = styled.label`
   display: block;
   margin-bottom: 8px;
   font-weight: 600;
   font-size: 18px;
   line-height: 24px;
   letter-spacing: 0.3px;
   color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.dark)};
`

const StyledErrorMsg = styled.span`
   // position: absolute;
   // left: 0;
   // bottom: 2px;
   display: block;
   margin-top: 8px;
   color: ${({ theme }) => theme.colors.error};
`

const StyledInputWrapper = styled.div`
   position: relative;
   margin-bottom: 24px;

   input {
      width: 100%;
      height: 48px;
      padding: 0 16px;
      border: none;
      outline: none;
      background: ${({ theme }) => theme.colors.grey_5};
      color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.dark)};
      background: ${({ theme }) => (theme.darkMode ? theme.colors.grey_6 : theme.colors.white)};
      border: ${({ theme }) =>
         theme.darkMode ? `2px solid ${theme.colors.grey_3}` : `2px solid ${theme.colors.grey_4}`};

      &:disabled {
         background: ${({ theme }) => theme.colors.grey_3};
         cursor: not-allowed;
      }

      &:focus {
         border: solid 2px ${({ theme }) => theme.colors.hotCinnamon};
      }
   }
`

InputField.displayName = 'Input Field'

// Select Field
export const SelectField = React.forwardRef(({ id, label, required, error, options, ...rest }, ref) => {
   return (
      <StyledSelectWrapper>
         <StyledLabel htmlFor={id}>{`${label}${required ? '*' : ''}`}</StyledLabel>
         <select {...rest} ref={ref} id={id}>
            {options.map(({ key, value }) => (
               <option key={key} value={value}>
                  {key}
               </option>
            ))}
         </select>
         {error && <StyledErrorMsg role="alert">{error.message}</StyledErrorMsg>}
      </StyledSelectWrapper>
   )
})

const StyledSelectWrapper = styled.div`
   position: relative;
   margin-bottom: 24px;

   select {
      width: 100%;
      height: 48px;
      padding: 0 16px;
      outline: none;
      background-color: ${({ theme }) => (theme.darkMode ? theme.colors.grey_6 : theme.colors.white)};
      background-image: url(${iconDown});
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: calc(100% - 16px);
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      font-size: 16px;
      color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.dark)};
      border: ${({ theme }) =>
         theme.darkMode ? `2px solid ${theme.colors.grey_3}` : `2px solid ${theme.colors.grey_4}`};

      &:focus {
         border: solid 2px ${({ theme }) => theme.colors.hotCinnamon};
      }

      &:disabled {
         background-color: ${({ theme }) => theme.colors.grey_3};
         cursor: not-allowed;
      }

      option {
         color: inherit;
         font-size: inherit;
         line-height: inherit;
      }
   }

   select::-ms-expand {
      display: none;
   }
`

SelectField.displayName = 'Select Field'

// Textarea Field

export const TextAreaField = React.forwardRef(({ id, label, required, error, ...rest }, ref) => {
   return (
      <StyledTextareaWrapper>
         <StyledLabel htmlFor={id}>{`${label}${required ? '*' : ''}`}</StyledLabel>
         <textarea id={id} {...rest} ref={ref} />
         {error && <StyledErrorMsg role="alert">{error.message}</StyledErrorMsg>}
      </StyledTextareaWrapper>
   )
})

const StyledTextareaWrapper = styled.div`
   position: relative;
   margin-bottom: 24px;

   textarea {
      width: 100%;
      height: 270px;
      padding: 16px;
      border: none;
      outline: none;
      background: ${({ theme }) => theme.colors.grey_5};
      resize: none;
      color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.dark)};
      background: ${({ theme }) => (theme.darkMode ? theme.colors.grey_6 : theme.colors.white)};
      border: ${({ theme }) =>
         theme.darkMode ? `2px solid ${theme.colors.grey_3}` : `2px solid ${theme.colors.grey_4}`};

      &:focus {
         border: solid 2px ${({ theme }) => theme.colors.hotCinnamon};
      }

      &:disabled {
         background: ${({ theme }) => theme.colors.grey_3};
         cursor: not-allowed;
      }
   }
`

TextAreaField.displayName = 'Textarea Field'

// InputFile Field

export const InputFileField = React.forwardRef(({ id, error, actualValue, ...rest }, ref) => {
   return (
      <StyledInputFileWrapper>
         <InfoPanel>
            <h4>Have some materials to share with us?</h4>
            <p>Attach your investment deck, white paper, blog post, or other materials </p>
         </InfoPanel>
         <FileUploadWrapper>
            <input type="file" {...rest} id={id} ref={ref} aria-invalid={error?.message ? 'true' : 'false'} />
            <label htmlFor={id}>
               <FontAwesome icon="attachment" /> Attach Deck
            </label>
         </FileUploadWrapper>
         {actualValue && actualValue[0] && (
            <StyledFileName>
               <FontAwesome icon="file" />
               {actualValue[0].name}
            </StyledFileName>
         )}
         {error && <StyledErrorMsg role="alert">{error.message}</StyledErrorMsg>}
      </StyledInputFileWrapper>
   )
})

const StyledInputFileWrapper = styled.div`
   position: relative;
   padding-bottom: 20px;

   ${StyledErrorMsg} {
      left: 50%;
      transform: translateX(-50%);

      ${media.greaterThan('sm')`
         left: 0;
         transform: translateX(0);
      `}
   }
`

const InfoPanel = styled.div`
   text-align: center;

   ${media.greaterThan('sm')`
      text-align: left;
   `}

   h4 {
      margin-bottom: 0;
      font-family: ${({ theme }) => theme.fonts.titillium};
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => (theme.darkMode ? theme.colors.textColor : '#374151')};
   }

   p {
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.grey_3};
   }
`

const FileUploadWrapper = styled.div`
   position: relative;
   width: 208px;
   height: 61px;
   margin: 0 auto;

   ${media.greaterThan('sm')`
      margin: 0;
   `}

   input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:disabled {
         cursor: not-allowed;
      }
   }

   label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: ${({ theme }) =>
         theme.darkMode ? `2px solid ${theme.colors.grey_3}` : `2px solid ${theme.colors.textColor}`};
      box-shadow: ${({ theme }) => (theme.darkMode ? 'none' : `-2px 2px 2px ${theme.colors.grey_2}`)};
      background-color: ${({ theme }) => (theme.darkMode ? theme.colors.grey_6 : theme.colors.white)};
      transition: 0.4s cubic-bezier(0, 0.89, 0.44, 1);
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;

      svg {
         display: block;
         margin-right: 20px;
      }
   }

   input:hover + label {
      background-color: ${({ theme }) => theme.colors.hotCinnamon};
      color: ${({ theme }) => theme.colors.white};
      border: ${({ theme }) => `2px solid ${theme.colors.hotCinnamon}`};
   }

   input:disabled + label {
      background-color: ${({ theme }) => theme.colors.grey};
      border: ${({ theme }) => `2px solid ${theme.colors.grey}`};
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.grey_3};
      box-shadow: none;
   }
`

const StyledFileName = styled.span`
   display: block;
   margin-top: 6px;
   text-align: center;
   font-size: 12px;
   font-weight: bold;

   svg {
      display: inline-block;
      margin-right: 10px;
   }

   ${media.greaterThan('sm')`
      text-align: left;
   `}
`

InputFileField.displayName = 'Textarea Field'

// Checkbox Field

export const NewsletterField = React.forwardRef(({ id, required, error, ...rest }, ref) => {
   return (
      <StyledNewsletterWrapper>
         <input type="checkbox" {...rest} id={id} ref={ref} aria-invalid={error?.message ? 'true' : 'false'} />
         <label htmlFor={id}>{`Subscribe to our newsletter? ${required ? '*' : ''}`}</label>
         <div>
            <p>Get the best insights in crypto delivered directly to your inbox.</p>
         </div>
         {error && <StyledErrorMsg role="alert">{error.message}</StyledErrorMsg>}
      </StyledNewsletterWrapper>
   )
})

const StyledNewsletterWrapper = styled.div`
   position: relative;
   width: 224px;
   margin: 0 auto 24px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;

   ${media.greaterThan('sm')`
      width: 100%;
      margin: 0 0 24px;
      justify-content: flex-start;
   `}

   input {
      flex: 0 1 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      appearance: none;
      background-color: ${({ theme }) => (theme.darkMode ? theme.colors.grey_3 : theme.colors.white)};
      border: ${({ theme }) =>
         theme.darkMode ? `2px solid ${theme.colors.grey_3}` : `2px solid ${theme.colors.textColor}`};
      position: relative;
      outline: none;

      &:checked {
         background-color: ${({ theme }) => theme.colors.hotCinnamon};
      }

      &:disabled {
         background-color: ${({ theme }) => theme.colors.grey_3};
         cursor: not-allowed;
      }

      ${media.greaterThan('sm')`
         margin-right: 12px;
         flex: 0 1 16px;
         width: 16px;
         height: 16px;
      `}
   }

   label {
      flex: 0 1 196px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;

      ${media.greaterThan('sm')`
         flex: 0 1 400px;
      `}
   }

   div {
      flex: 0 1 196px;
      margin-left: auto;

      ${media.greaterThan('sm')`
         flex: 0 1 100%;
         margin-left: 28px;
      `}

      p {
         font-size: 16px;
         line-height: 20px;
         color: ${({ theme }) => theme.colors.grey_3};
         margin-bottom: 0;
      }
   }
`

NewsletterField.displayName = 'Newsletter Field'

// Submit button

export function SubmitButton({ status }) {
   return (
      <StyledSubmitWrapper>
         <StyledSubmitBtn type="submit" disabled={status === 'sending'}>
            <FontAwesome icon="emailOutline" />
            Submit
         </StyledSubmitBtn>
         {status === 'sending' && (
            <LoadingState>
               <FontAwesome icon="spinner" pulse /> <span>Loading...</span>
            </LoadingState>
         )}
         {status === 'success' && (
            <SuccessState>
               <FontAwesome icon="success" />
               <span>Your inquiry has been sent</span>
            </SuccessState>
         )}
         {status === 'error' && (
            <ErrorState>
               <FontAwesome icon="exclamationCircle" />
               <span>We encountered some problems while submitting your form</span>
            </ErrorState>
         )}
      </StyledSubmitWrapper>
   )
}

const StyledSubmitWrapper = styled.div`
   text-align: center;

   ${media.greaterThan('sm')`
      text-align: left;
   `}
`

const StyledSubmitBtn = styled(Button)`
   width: 208px;
   margin: 0 auto;

   ${media.greaterThan('sm')`
      margin: 0;
   `}
`

const StyledState = styled.div`
   margin: 24px auto 0;
   width: 80%;
   font-weight: bold;
   font-size: 12px;
   line-height: 1.4;

   ${media.greaterThan('sm')`
      width: 250px;
      margin: 24px 0 0;
      display: flex;
      align-items: center;
      font-size: 16px;
   `}

   svg {
      display: inline-block;
      margin-right: 10px;
   }
`

const LoadingState = styled(StyledState)`
   color: ${({ theme }) => theme.colors.hotCinnamon};
`
const SuccessState = styled(StyledState)`
   color: ${({ theme }) => theme.colors.hotCinnamon};
   opacity: 0;
   animation: 'fadeIn' 3s ease-in;
`
const ErrorState = styled(StyledState)`
   color: ${({ theme }) => theme.colors.error};
`

export function renderConditionalLabel(name) {
   switch (name) {
      case 'news':
         return 'Which story did you read?'
      case 'event':
         return 'Which event did you attend?'
      case 'referral':
         return 'Who is the person you refer to?'
      default:
         return null
   }
}
