import React from 'react'
import { graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import Markdown from 'components/Markdown'
import { Container, Row, Column } from 'components/Grid'

import Section from './Section'
import Logo from './Logo'

const Sponsors = ({ sponsorsTitle, sponsors }) => (
   <Section>
      <Container>
         <Row>
            <Column>
               {!isEmpty(sponsorsTitle) && !isEmpty(sponsorsTitle.markdown) && (
                  <Markdown>{sponsorsTitle.markdown}</Markdown>
               )}
            </Column>
         </Row>
         <Row justifyContent="center">
            {sponsors &&
               sponsors.map(
                  (sponsor) =>
                     sponsor.file && (
                        <Logo key={sponsor.id} width={[1, 1, 1 / 4]}>
                           <img src={sponsor.file.url} alt={sponsor.description} />
                        </Logo>
                     )
               )}
         </Row>
      </Container>
   </Section>
)

export const query = graphql`
   fragment SponsorsData on ContentfulPartialSponsors {
      id
      sponsorsTitle {
         markdown: sponsorsTitle
      }
      sponsors {
         id
         description
         file {
            url
         }
      }
   }
`

export default Sponsors
