import React from 'react'
import styled from 'styled-components'

import Markdown from 'components/Markdown'

export default function SmallText({ content }) {
   return (
      <StyledSmallText>
         <Markdown options={{ forceBlock: true }}>{content}</Markdown>
      </StyledSmallText>
   )
}

const StyledSmallText = styled.div`
   p,
   em,
   h1,
   h2,
   h3 {
      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
   }

   strong {
      font-size: 15px;
      font-weight: 700;
   }

   a {
      color: ${({ theme }) => theme.colors.hotCinnamon};
   }
`
