import styled from 'styled-components'

import { Column } from 'components/Grid'

import media from 'utils/media'

export default styled(Column)`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 23px;

   &:last-of-type {
      margin-bottom: 0;
   }

   ${media.greaterThan('md')`
    margin-bottom: 0;
  `};

   img {
      max-height: 62px;
      object-fit: contain;
      margin-bottom: 0;
      background-color: #fff;

      ${media.greaterThan('md')`
      max-height: 40px;
    `};

      ${media.greaterThan('lg')`
      max-height: 62px;
    `};
   }
`
