import React, { useEffect } from 'react'

import { Row, Column } from 'components/Grid'
import { InputField, SelectField } from './FormField'

export default function InvestmentForm({ isActive, register, errors, formStatus, unregister }) {
   useEffect(() => {
      return () => {
         console.log(0, 'UNMOUNTING INVESTMENT')
         unregister(['stage', 'url', 'telegram', 'twitter'])
      }
   }, [])

   return (
      <Row style={{ display: isActive ? 'flex' : 'none' }}>
         <Column width={[1, 1 / 2]}>
            <SelectField
               {...register('stage', {
                  required: 'This field is required',
               })}
               id="stage"
               label="Stage"
               required
               error={errors.stage}
               options={[
                  { key: 'Choose your category', value: '' },
                  { key: 'Seed', value: 'Seed' },
                  { key: 'Series A', value: 'Series A' },
                  { key: 'Series B', value: 'Series B' },
                  { key: 'Late Stage', value: 'Late Stage' },
               ]}
               disabled={formStatus === 'sending'}
            />
         </Column>
         <Column width={[1, 1 / 2]}>
            <InputField
               {...register('url')}
               id="url"
               label="URL"
               error={errors.url}
               disabled={formStatus === 'sending'}
            />
         </Column>
         <Column width={[1, 1 / 2]}>
            <InputField
               {...register('telegram')}
               id="telegram"
               label="Telegram Handle"
               error={errors.telegram}
               disabled={formStatus === 'sending'}
            />
         </Column>
         <Column width={[1, 1 / 2]}>
            <InputField
               {...register('twitter')}
               id="twitter"
               label="Twitter Handle"
               error={errors.twitter}
               disabled={formStatus === 'sending'}
            />
         </Column>
      </Row>
   )
}
