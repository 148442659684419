import styled from 'styled-components'

import media from 'utils/media'

const Section = styled.section`
   padding-top: 36px;
   padding-bottom: 42px;
   position: relative;
   overflow: hidden;

   ${media.greaterThan('lg')`
    padding-bottom: 74px;
  `};

   & > div > div {
      align-items: center;

      ${media.greaterThan('lg')`
      align-items: flex-start;
    `};
   }
`

export default Section
