import styled from 'styled-components'
import media from 'utils/media'

export const StyledSection = styled.section`
   padding-top: 45px;
   padding-bottom: 72px;

   ${media.greaterThan('md')`
      padding-top: 61px;
   `};
`
export const StyledTitle = styled.h1`
   font-size: 50px;
   line-height: 66px;
   color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.dark)};
   margin-bottom: 24px;
   letter-spacing: -0.5px;
   font-weight: 500;
`

export const StyledContent = styled.div`
   margin-bottom: 28px;

   ${media.greaterThan('md')`
      margin-bottom: 64px;
   `};

   ${media.greaterThan('lg')`
      margin-bottom: 0;
   `};

   p {
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.4px;
      margin-bottom: 30px;

      &:last-of-type {
         margin-bottom: 0;
      }
   }
`

export const StyledContactPerson = styled.div`
   padding: 34px 24px 48px;
   background-color: ${({ theme }) => (theme.darkMode ? '#353535' : theme.colors.grey_5)};

   ${media.greaterThan('md')`
      width: 400px;
      padding: 24px 36px 16px;
   `};

   ${media.greaterThan('lg')`
      width: 100%;
      padding: 24px 36px 60px;
   `};

   h3 {
      padding-bottom: 10px;
      margin-bottom: 24px;
      font-family: ${({ theme }) => theme.fonts.titillium};
      font-size: 28px;
      line-height: 43px;
      font-weight: 700;
      letter-spacing: 1.5px;
      color: ${({ theme }) => theme.colors.hotCinnamon};
      text-transform: uppercase;
      border-bottom: 2px solid #4a4a4a;

      ${media.greaterThan('md')`
         font-size: 32px;
         line-height: 49px;
      `};
   }
`

export const StyledLabel = styled.span`
   display: block;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.36px;
`

export const StyledPerson = styled.div`
   width: 100%;
   margin-bottom: 27px;
   display: flex;
   align-items: center;
   justify-content: space-between;

   img {
      width: 70px;
      height: 70px;
      margin-bottom: 0;
      border-radius: 100%;
      overflow: hidden;
   }

   div {
      flex: 0 1 70%;

      strong {
         color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.textColor)};
         font-size: 18px;
         line-height: 27px;
      }
   }
`
export const StyledSocials = styled.div`
   a {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      transition: ${({ theme }) => theme.transitions.main};

      div {
         width: 29px;
         height: 29px;
         margin-right: 15px;
         flex: 0 0 29px;
         display: flex;
         justify-content: center;
         align-items: center;
         border-radius: 100%;
         overflow: hidden;
         background-color: ${({ theme }) => theme.colors.socialIconBg};
         color: ${({ theme }) => theme.colors.socialIcon};
         transition: ${({ theme }) => theme.transitions.main};
      }

      &:last-of-type {
         margin-bottom: 0;
      }

      &:hover {
         color: ${({ theme }) => theme.colors.hotCinnamon};
         font-weight: bold;

         div {
            background-color: ${({ theme }) => theme.colors.hotCinnamon};
         }
      }
   }
`
