import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import isEmpty from 'lodash/isEmpty'

import { Section, Heading, BackgroundImage } from './styles'

const NotFound = ({ text, image }) => {
   return (
      <Section>
         <BackgroundImage>
            {!isEmpty(image) && <GatsbyImage image={image.gatsbyImageData} alt={image.description} />}
         </BackgroundImage>
         {/* <Heading>{text}</Heading> */}
      </Section>
   )
}

export const query = graphql`
   fragment NotFound on ContentfulPartial404Data {
      text
      image {
         description
         gatsbyImageData
      }
   }
`

export default NotFound
