import React, { Component, createRef, Fragment } from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import { GatsbyImage } from 'gatsby-plugin-image'
import isEmpty from 'lodash/isEmpty'
import { InView } from 'react-intersection-observer'

import media from 'utils/media'
import getTranslations from 'utils/getTranslations'

import { LangContext } from 'context'

import Markdown from 'components/Markdown'
import Link from 'components/Link'
import { Row, Column } from 'components/Grid'
import FontAwesome from 'components/FontAwesome'

class TeamMember extends Component {
   constructor(props) {
      super(props)

      this.state = {
         collapsed: true,
         height: 0,
         inView: false,
      }

      this.content = createRef()
   }

   handleCollapse = () => {
      this.setState((prevState) => {
         if (!prevState.collapsed) {
            return { collapsed: true, height: 0 }
         }
         const nodeList = this.content.current.children
         const height = Array.prototype.map
            .call(nodeList, (node) => node.clientHeight)
            .reduce((a, b) => {
               return a + b
            }, 0)
         return { collapsed: false, height: height + 40 }
      })
   }

   handleInView = (inView) => {
      this.setState({
         inView,
      })
   }

   render() {
      const { id, bio, hideLabel, showLabel, name, position, photo, socials } = this.props || {}
      const { collapsed, height, inView } = this.state
      const { Consumer } = LangContext

      return (
         <Consumer>
            {({ lang }) => {
               const socialTranslations = getTranslations(lang, 'socials')
               return (
                  <InView id={id} as="div" onChange={this.handleInView} threshold={0.75} triggerOnce>
                     <PosedTeamMember collapsed={collapsed} height={height} pose={inView ? 'visible' : 'exit'}>
                        <StyledTeamMember collapsed={collapsed}>
                           <Column width={[1, 1, 1 / 2, 1 / 3]} className="image">
                              {!isEmpty(photo) && (
                                 <Fragment>
                                    <GrayScaleMask>
                                       <GatsbyImage image={photo.gatsbyImageData} />
                                       {/* <GatsbyImage image={photo.gatsbyImageData} /> */}
                                    </GrayScaleMask>
                                 </Fragment>
                              )}
                           </Column>
                           <Column width={[1, 1, 1 / 2, 2 / 3]} style={{ display: 'flex', flexDirection: 'column' }}>
                              <div className="name">
                                 <h4>{name}</h4>
                                 <span>{position}</span>
                              </div>

                              {!isEmpty(socials) && (
                                 <StyledSocials>
                                    {socials.map((social) => (
                                       <Link
                                          key={`${name}_${social.name}`}
                                          type="external"
                                          url={social.url}
                                          aria-label={socialTranslations[social.name]}
                                          target="_blank"
                                       >
                                          <FontAwesome icon={social.name} />
                                       </Link>
                                    ))}
                                 </StyledSocials>
                              )}
                              {!isEmpty(bio) && !isEmpty(bio.markdown) && (
                                 <Fragment>
                                    <div className="bio" ref={this.content}>
                                       <Markdown>{bio.markdown}</Markdown>
                                    </div>
                                    <button onClick={this.handleCollapse} className="collapse" role="button">
                                       {collapsed ? showLabel : hideLabel}
                                    </button>
                                 </Fragment>
                              )}
                           </Column>
                        </StyledTeamMember>
                     </PosedTeamMember>
                  </InView>
               )
            }}
         </Consumer>
      )
   }
}

export default TeamMember

const PosedTeamMember = posed(styled.div`
   position: relative;
   margin-top: 29px;
   padding-bottom: 27px;
   display: block;

   ${media.greaterThan('md')`
      margin-top: 26px;
      padding-bottom: 0;
   `};

   ${media.greaterThan('lg')`
      margin-top: 75px;
   `};

   &:first-of-type {
      ${media.greaterThan('md')`
         margin-top: 63px;
      `};

      ${media.greaterThan('lg')`
         margin-top: 75px;
      `};
   }

   &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 33px;
      right: 33px;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.border};

      ${media.greaterThan('sm')`
         left: 10px;
         right: 10px;
      `};

      ${media.greaterThan('md')`
         display: none;
      `};
   }
`)({
   visible: {
      opacity: 1,
      y: 0,
   },
   exit: {
      opacity: 0,
      y: 20,
   },
})

const StyledTeamMember = styled(Row)`
   .image {
      position: relative;

      .gatsby-image-wrapper {
         ${media.greaterThan('md')`
            width: 225px;
         `};

         img {
            margin-bottom: 0;
         }
      }
   }

   .name {
      margin-top: 30px;
      margin-bottom: 19px;

      ${media.greaterThan('md')`
         margin-top: 38px;
         margin-bottom: 16px;
      `};

      ${media.greaterThan('lg')`
         margin-bottom: 25px;
         margin-top: 0;
      `};

      h4,
      span {
         margin: 0;
         letter-spacing: 0.4px;
         font-size: 20px;

         ${media.greaterThan('lg')`
            font-size: 22px;
         `};
      }

      h4 {
         line-height: 1.3;

         ${media.greaterThan('lg')`
            display: inline-block;
            padding-right: 10px;
            border-right: 2px solid ${({ theme }) => theme.colors.border};
            line-height: 1;
         `};
      }

      span {
         line-height: 1.2;

         ${media.greaterThan('lg')`
            padding-left: 10px;
            line-height: 1;
         `};
      }
   }

   .bio {
      overflow: hidden;
      transition: 0.3s cubic-bezier(0.77, 0, 0.175, 1);
      display: flex;
      flex-direction: column;
      height: ${({ collapsed, height }) => (collapsed ? '0px' : `${height}px`)};

      ${media.greaterThan('lg')`
         height: auto;
         margin-bottom: 36px;
      `};

      p {
         font-size: 18px;
         margin-bottom: 0;
      }

      p + p {
         margin-top: 20px;
      }
   }

   .collapse {
      background: transparent;
      border: none;
      padding: 0;
      text-align: left;
      cursor: pointer;
      z-index: 100;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: ${({ collapsed }) => (collapsed ? '0px' : `24px`)};

      ${media.greaterThan('lg')`
         display: none;
      `};
   }
`

const GrayScaleMask = styled.div`
   bottom: 0;
   transition: 0.2s ease-out;
   -webkit-filter: grayscale(80%);
   -webkit-filter: grayscale(0.8);
   -moz-filter: grayscale(80%);
   -ms-filter: grayscale(80%);
   -o-filter: grayscale(80%);
   filter: grayscale(80%);

   ${media.greaterThan('sm')`
      left: 10px;
      right: 10px;
   `};

   &:hover {
      filter: none;
   }
`

const StyledSocials = styled.div`
   display: flex;
   align-items: center;
   margin-bottom: ${({ collapsed }) => (collapsed ? '0px' : '28px')};

   ${media.greaterThan('md')`
      margin-bottom: 28px;
   `};

   ${media.greaterThan('lg')`
      order: 5;
      margin-bottom: 0;
   `};

   a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 29px;
      height: 29px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.socialIconBg};
      color: ${({ theme }) => theme.colors.socialIcon};
      transition: ${({ theme }) => theme.transitions.main};

      &:hover {
         background-color: ${({ theme }) => theme.colors.hotCinnamon};
      }

      & + a {
         margin-left: 15px;
      }
   }
`
