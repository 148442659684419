import styled from 'styled-components'

import media from 'utils/media'

export const StyledSection = styled.section`
   padding: 70px 0;
`

export const StyledHero = styled.div`
   h1,
   h2,
   h3 {
      font-size: 48px;
      line-height: 63px;
      letter-spacing: 1.1px;
      color: ${({ theme }) => theme.colors.header};
   }

   p {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.4px;

      &:last-of-type {
         margin-bottom: 0;
      }
   }
`

export const StyledFilters = styled.div`
   margin: 24px 0;
   width: 100%;

   ${media.greaterThan('sm')`
      display: flex;
      justify-content: space-between;
   `};

   ${media.greaterThan('md')`
         margin: 24px 0 48px;
   `};

   div {
      ${media.greaterThan('sm')`
         width: 45%;
         flex: 0 1 45%;
      `};

      ${media.greaterThan('md')`
         width: 48%;
         flex: 0 1 48%;
      `};

      ${media.greaterThan('md')`
         width: 49%;
         flex: 0 1 49%;
      `};
   }

   div + div {
      margin-top: 24px;

      ${media.greaterThan('sm')`
         margin-top: 0;
      `};
   }
`
