import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import Markdown from 'markdown-to-jsx'
import { Container, Row } from 'components/Grid'
import { Content } from './styles'

export default function MarketplaceSection({ content }) {
   const wrapperRef = useRef()
   // useEffect(() => {
   //    if (typeof window !== 'undefined') {
   //       window.addEventListener('message', receiveMessage)
   //    }

   //    return () => {
   //       window.removeEventListener('message', receiveMessage)
   //    }
   // }, [])

   // function receiveMessage(event) {
   //    if (wrapperRef.current) {
   //       if (event?.data?.type === 'size_updated') {
   //          wrapperRef.current.style.height = event?.data?.content?.height + 'px'
   //       }

   //       if (event?.data?.type === 'navigation_end') {
   //          setTimeout(() => {
   //             wrapperRef.current.scrollIntoView({ behavior: 'smooth' })
   //          }, 100)
   //       }
   //    }
   // }
   return (
      <section>
         <Container>
            <Row>
               <Content>
                  <div ref={wrapperRef}>
                     {!isEmpty(content) && !isEmpty(content.markdown) && <Markdown>{content.markdown}</Markdown>}
                  </div>
               </Content>
            </Row>
         </Container>
      </section>
   )
}

export const query = graphql`
   fragment MarkdownSectionQuery on ContentfulPartialMarketplace {
      id
      content {
         markdown: content
      }
   }
`
