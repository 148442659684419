import React from 'react'
import { graphql } from 'gatsby'

import { Container, Row, Column } from 'components/Grid'
import { downloadBlob } from 'utils/helpers'

import {
   StyledLogo,
   StyledLogosList,
   StyledSection,
   StyledSmallTitle,
   StyledLabel,
   StyledButton,
   StyledHeadshotsList,
   StyledLogoInfo,
   StyledHeadshot,
   StyledHeadshotInfo,
} from './styles'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function PressResources({ headshotsTitle, logosTitle, logosList, headshotsList }) {
   async function donwloadFile(url, filename) {
      await fetch(url)
         .then((res) => {
            res.arrayBuffer().then(function (buffer) {
               const blob = new Blob([buffer])
               downloadBlob(blob, filename)
            })
         })
         .catch((err) => console.log('Problem with fetching file: ', err))
   }

   return (
      <StyledSection>
         <Container>
            <Row>
               <Column>
                  <StyledSmallTitle>{logosTitle}</StyledSmallTitle>
                  {Array.isArray(logosList) && (
                     <StyledLogosList>
                        {logosList.map((logo) => (
                           <StyledLogo key={logo.id}>
                              {logo.png && <GatsbyImage image={logo.png.gatsbyImageData} alt={logo.title} />}
                              <StyledLogoInfo>
                                 <StyledLabel>{logo.title}</StyledLabel>
                                 {logo.jpeg && (
                                    <StyledButton onClick={() => donwloadFile(logo.jpeg.url, logo.jpeg.filename)}>
                                       JPEG
                                    </StyledButton>
                                 )}
                                 {logo.png && (
                                    <StyledButton onClick={() => donwloadFile(logo.png.url, logo.png.filename)}>
                                       PNG
                                    </StyledButton>
                                 )}
                                 {logo.svg && (
                                    <StyledButton onClick={() => donwloadFile(logo.svg.url, logo.svg.filename)}>
                                       SVG
                                    </StyledButton>
                                 )}
                              </StyledLogoInfo>
                           </StyledLogo>
                        ))}
                     </StyledLogosList>
                  )}
                  <StyledSmallTitle>{headshotsTitle}</StyledSmallTitle>
                  {Array.isArray(headshotsList) && (
                     <StyledHeadshotsList>
                        {headshotsList.map((headshot) => (
                           <StyledHeadshot key={headshot.id}>
                              {headshot.gatsbyImageData && (
                                 <GatsbyImage image={headshot.gatsbyImageData} alt={headshot.title} />
                              )}
                              <StyledHeadshotInfo>
                                 <StyledLabel>{headshot.title} | </StyledLabel>
                                 <StyledButton onClick={() => donwloadFile(headshot.url, headshot.filename)}>
                                    download
                                 </StyledButton>
                              </StyledHeadshotInfo>
                           </StyledHeadshot>
                        ))}
                     </StyledHeadshotsList>
                  )}
               </Column>
            </Row>
         </Container>
      </StyledSection>
   )
}

export const query = graphql`
   fragment PressResources on ContentfulPartialPressResources {
      id
      headshotsTitle
      logosTitle
      logosList {
         id
         title
         jpeg {
            url
            filename
         }
         png {
            url
            filename
            gatsbyImageData
         }
         svg {
            url
            filename
         }
      }
      headshotsList {
         id
         url
         filename
         gatsbyImageData
         title
      }
   }
`
