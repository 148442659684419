import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

import FontAwesome from 'components/FontAwesome'
import debounce from 'lodash/debounce'

export default function SearchFilter({ onChange, placeholder }) {
   const [searchValue, setSearchValue] = useState('')

   function handleOnKeyPress(e) {
      if (e.key === 'Enter') {
         onChange(searchValue)
      }
   }

   const debounceFn = useCallback(debounce(handleDebounceFn, 300), [])

   function handleDebounceFn(inputValue) {
      onChange(inputValue)
   }

   function handleOnChange(e) {
      const trimmedValue = e.target.value.trimStart()
      setSearchValue(trimmedValue)
      debounceFn(trimmedValue)
   }

   return (
      <Wrapper>
         <input
            type="text"
            value={searchValue}
            onChange={handleOnChange}
            placeholder={placeholder}
            onKeyDown={handleOnKeyPress}
         />
         <button onClick={() => onChange(searchValue)}>
            <FontAwesome icon="search" />
         </button>
      </Wrapper>
   )
}

const Wrapper = styled.div`
   position: relative;

   input {
      padding: 8px 16px;
      width: 100%;
      height: 42px;
      color: ${({ theme }) => theme.colors.textColor};
      background: ${({ theme }) => (theme.darkMode ? theme.colors.grey_6 : theme.colors.white)};
      border: ${({ theme }) =>
         theme.darkMode ? `2px solid ${theme.colors.grey_3}` : `2px solid ${theme.colors.grey_4}`};
      border-radius: 0px;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.4px;
      outline: none;

      &:hover {
         box-shadow: ${({ smallVariant }) => (smallVariant ? '-2px 2px 2px #d8d8d8' : 'none')};
      }

      &:focus {
         border: solid 2px ${({ theme }) => theme.colors.hotCinnamon};
         box-shadow: none;
      }

      &::placeholder {
         font-size: 14px;
         color: ${({ theme }) => theme.colors.textColor};
      }
   }

   button {
      padding: 0;
      background-color: transparent;
      border: none;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: ${({ theme }) => theme.colors.grey_3};
   }
`
