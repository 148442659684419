import React, { Component } from 'react'
import { graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'
import Swiper from 'react-id-swiper'
import { Pagination } from 'swiper'

import Markdown from 'components/Markdown'
import { Container, Row } from 'components/Grid'

import Section from './Section'
import Content from './Content'
import SwiperContainer from './SwiperContainer'
import Element from './Element'

class ContentList extends Component {
   state = {
      slider: false,
   }

   componentDidMount() {
      this.breakpoint = window.matchMedia('(min-width:768px)')

      if (this.breakpoint.matches) {
         this.setState({ slider: false })
      } else {
         this.setState({ slider: true })
      }

      this.breakpoint.addListener(this.handleMatchMedia)
   }

   componentWillUnmount() {
      this.breakpoint.removeListener(this.handleMatchMedia)
   }

   handleMatchMedia = (event) => {
      if (event.matches) {
         this.setState({ slider: false })
      } else {
         this.setState({ slider: true })
      }
   }

   addLeadingZero = (value) => {
      if (value > 0 && value < 10) {
         return `0${value}`
      }
      return value
   }

   render() {
      const { titleContent, swipe, elements, greyBackground } = this.props
      const { slider } = this.state
      const params = {
         modules: [Pagination],
         pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
         },
      }

      return (
         <Section background={greyBackground}>
            <Container>
               <Row>
                  {!isEmpty(titleContent) && !isEmpty(titleContent.content) && !isEmpty(titleContent.content.markdown) && (
                     <Content width={[1, 1, 10 / 12]} textCenter={titleContent.textCenter} border={titleContent.border}>
                        <Markdown>{titleContent.content.markdown}</Markdown>
                     </Content>
                  )}
               </Row>
               <Row>
                  {swipe && slider ? (
                     <SwiperContainer>
                        <Swiper {...params}>
                           {!isEmpty(elements) &&
                              elements.map((element, i) => (
                                 <div key={element.name}>
                                    <span>{`${this.addLeadingZero(i + 1)}/${this.addLeadingZero(
                                       elements.length
                                    )}`}</span>
                                    {!isEmpty(element.content) && !isEmpty(element.content.markdown) && (
                                       <Markdown>{element.content.markdown}</Markdown>
                                    )}
                                 </div>
                              ))}
                        </Swiper>
                        <span>SWIPE LEFT</span>
                        <hr />
                     </SwiperContainer>
                  ) : (
                     !isEmpty(elements) &&
                     elements.map((element) => (
                        <Element key={element.name} width={[1, 1, 1 / 3]} textCenter={element.textCenter}>
                           {!isEmpty(element.content) && !isEmpty(element.content.markdown) && (
                              <Markdown>{element.content.markdown}</Markdown>
                           )}
                        </Element>
                     ))
                  )}
               </Row>
            </Container>
         </Section>
      )
   }
}

export const query = graphql`
   fragment ContentListData on ContentfulComponentContentList {
      id
      titleContent {
         id
         content {
            markdown: content
         }
         textCenter
         border
      }
      swipe
      elements {
         name
         content {
            markdown: content
         }
         textCenter
      }
      greyBackground
   }
`

export default ContentList
