import styled from 'styled-components'

import media from 'utils/media'

export const Heading = styled.h1`
   left: 10%;
   top: 250px;
   mix-blend-mode: normal;
   opacity: 0.61;
   /* text-shadow: 0px 2px 11px rgba(0, 0, 0, 0.5); */
   filter: blur(2.71828px);
   transform: rotate(-10deg);
   font-size: 65px;
   font-family: 'Times New Roman';
   line-height: 75px;
   color: ${({ theme }) => theme.colors.darker};

   ${media.greaterThan('md')`
    left: 40%;
    top: 350px;
  `};

   ${media.greaterThan('lg')`
    left: 45%;
    top: 450px;
  `};

   ${media.greaterThan('xl')`
    left: 50%;
    top: 500px;
  `};
`

export const BackgroundImage = styled.div`
   /* top: 0;
   left: 0;
   width: 100%;
   height: 100%; */
   background-color: ${({ theme }) => theme.colors.grey};

   .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
   }
`

export const Section = styled.section`
   position: relative;
   /* min-height: 440px;

   ${media.greaterThan('md')`
    min-height: 600px;
  `};

   ${media.greaterThan('lg')`
    min-height: 700px;
  `};

   ${media.greaterThan('xl')`
    min-height: 800px;
  `}; */

   ${Heading} {
      position: absolute;
   }
`
