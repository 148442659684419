import { zh, en, ko, ja } from 'lang'

export default function getTranslations(lang, component) {
   if (!lang || !component) {
      return null
   }

   if (lang === 'en-US') {
      const content = en[component] ? en[component] : 'Wrong component name'
      return content
   } else if (lang === 'zh') {
      const content = zh[component] ? zh[component] : 'Wrong component name'
      return content
   } else if (lang === 'ko') {
      const content = ko[component] ? ko[component] : 'Wrong component name'
      return content
   } else if (lang === 'ja') {
      const content = ja[component] ? ja[component] : 'Wrong component name'
      return content
   }

   return {}
}
