export default {
   languages: [
      {
         lang: 'en-US',
         label: 'English',
         disabled: false,
      },
      {
         lang: 'zh',
         label: '普通話',
         disabled: false,
      },
      {
         lang: 'ko',
         label: '한국어',
         disabled: false,
      },
      {
         lang: 'ja',
         label: '日本語',
         disabled: false,
      },
   ],
   projectCategory: {
      LAYER1: 'LAYER1',
      LAYER2: 'LAYER2',
      INFRASTRUCTURE: 'INFRASTRUCTURE',
      IDENTITY: 'IDENTITY',
      PRIVACY: 'PRIVACY',
      ENTERPRISE: 'ENTERPRISE',
      MOBILE: 'MOBILE',
   },
   socials: {
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
      email: 'Email',
      reddit: 'Reddit',
      telegram: 'Telegram',
   },
   hamburgerMenu: {
      label: 'menu',
   },
}
