// Example:
// trackEvent("Contact Button Clicked", label)
export default (event, label, value, options) => {
   const splitPoint = event.lastIndexOf(' ')
   const category = event.slice(0, splitPoint)
   const action = event.slice(splitPoint + 1)

   if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'event', category, action, label, value, options)
   }

   if (process.env.NODE_ENV === 'development') {
      console.log({
         category,
         action,
         label,
         value,
         options,
      })
   }
}
