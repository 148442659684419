import styled from 'styled-components'

import { Container, Column } from 'components/Grid'

import media from 'utils/media'

export const Section = styled.section`
   padding: 31px 0 0px;

   ${media.greaterThan('md')`
      padding: 55px 0 35px;
   `};

   ${media.greaterThan('lg')`
      padding: 100px 0 40px;
   `};

   h2 {
      margin-bottom: 27px;

      ${media.greaterThan('md')`
         margin-bottom: 33px;
      `};

      ${media.greaterThan('lg')`
         font-size: 57px;
         line-height: 73.5px;
      `};
   }
`

export const StyledContainer = styled(Container)`
   position: relative;
   padding-bottom: 48px;

   &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 16px;
      right: 16px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.border};

      ${media.greaterThan('lg')`
         left: 12px;
         right: auto;
         width: 58%;
      `};
   }
`

export const Content = styled(Column)`
   position: relative;
   margin-bottom: 40px;

   ${media.greaterThan('lg')`
      margin-bottom: 0px;
   `};

   p {
      margin-bottom: 0;
   }

   p + p {
      margin-top: 28px;
   }
`

export const Image = styled(Column)`
   position: relative;

   .gatsby-image-wrapper {
      max-height: 250px;

      ${media.greaterThan('md')`
         max-height: 352px;
      `};

      ${media.greaterThan('lg')`
         max-height: 674px;
      `};

      img {
         margin-bottom: 0;
      }
   }
`
