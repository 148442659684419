import styled from 'styled-components'

import { Column } from 'components/Grid'

export default styled(Column)`
   text-align: center;
   position: relative;

   .swiper-wrapper {
      padding-bottom: 78px;
   }

   & > span,
   .swiper-slide span {
      display: block;
      font-size: 12px;
      line-height: 2.67;
      letter-spacing: 1.5px;
   }

   & > span {
      margin-top: 24px;
   }

   .swiper-slide {
      span {
         margin-bottom: 24px;
      }

      h4 {
         font-size: 22px;
         color: ${({ theme }) => theme.colors.header};
      }
   }

   hr {
      width: 100%;
      margin: 42px auto 0;
      background-color: ${({ theme }) => theme.colors.hrBg};
      height: 2px;
   }
`
