import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import isEmpty from 'lodash/isEmpty'

import Markdown from 'components/Markdown'

import Section from './Section'
import Heading from './Heading'
import BackgroundImage from './BackgroundImage'

const Hero = ({ heroTitle, textPosition, background, largeText }) => {
   return (
      <Section textPosition={textPosition}>
         <BackgroundImage>
            {!isEmpty(background) && <GatsbyImage image={background.gatsbyImageData} alt="background" />}
         </BackgroundImage>
         <Heading textPosition={textPosition} largeText={largeText}>
            {!isEmpty(heroTitle) && !isEmpty(heroTitle.markdown) && <Markdown>{heroTitle.markdown}</Markdown>}
         </Heading>
      </Section>
   )
}

export const query = graphql`
   fragment HeroData on ContentfulComponentHero {
      id
      heroTitle {
         markdown: heroTitle
      }
      textPosition
      background {
         gatsbyImageData(width: 2400, quality: 85)
      }
      largeText
   }
`

export default Hero
