import styled from 'styled-components'

import { Column } from 'components/Grid'

import media from 'utils/media'

export default styled(Column)`
   position: relative;

   ${media.greaterThan('md')`
      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 100%;
        left: 20px;
        top: 0;
        bottom: 0;
        background-color: ${({ theme }) => theme.colors.lightGreyBg};
        z-index: 0;

        ${media.greaterThan('sm')`
          left: -10px;
        `};

        ${media.greaterThan('md')`
          display: none;
        `};
      }
  `};

   ${media.greaterThan('lg')`
      min-height: 737px;
   `};

   div {
      padding-top: 36px;
      padding-bottom: 36px;
      position: relative;
      z-index: 1;

      ${media.greaterThan('md')`
         padding: 87px 0 86px 40%;
         height: 100%;
         background-color: ${({ theme }) => theme.colors.lightGreyBg};
      `};

      ${media.greaterThan('lg')`
         padding-top: 108px;
      `};

      h2 {
         margin-bottom: 30px;
         letter-spacing: -0.5px;

         ${media.greaterThan('md')`
            margin-bottom: 38px;
         `};

         ${media.greaterThan('lg')`
            margin-bottom: 50px;
         `};
      }

      span {
         display: block;
         text-transform: uppercase;
         font-size: 14px;
         letter-spacing: 1.5px;
         padding-bottom: 26px;

         ${media.greaterThan('md')`
            font-size: 17px;
            padding-bottom: 50px;
         `};
      }

      p {
         margin-bottom: 30px;
         letter-spacing: -0.4px;

         &:last-of-type {
            margin-bottom: 0;
         }
      }

      & > a {
         display: inline-block;
         margin: 62px 0;

         &:last-child {
            margin-bottom: 0;

            ${media.greaterThan('md')`
               margin-top: 50px;
            `};
         }
      }
   }
`
