import React from 'react'
import { graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import Markdown from 'components/Markdown'
import { Container, Row } from 'components/Grid'

import { Section } from './Section'
import { Content } from './Content'

const LongText = ({ content, textCenter, border, isIframeOnly }) => {
   return (
      <Section withoutPadding={isIframeOnly}>
         <Container>
            <Row>
               <Content textCenter={textCenter} border={border}>
                  {!isEmpty(content) && !isEmpty(content.markdown) && <Markdown>{content.markdown}</Markdown>}
               </Content>
            </Row>
         </Container>
      </Section>
   )
}

export const query = graphql`
   fragment LongTextData on ContentfulComponentLongText {
      id
      content {
         markdown: content
      }
      textCenter
      border
      isIframeOnly
   }
`

export default LongText
