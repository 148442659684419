import React, { useEffect } from 'react'

import { Row, Column } from 'components/Grid'
import { InputField, SelectField } from './FormField'

export default function FoundForm({ isActive, register, errors, formStatus, unregister }) {
   useEffect(() => {
      return () => {
         console.log(0, 'UNMOUNTING FOUND')
         unregister(['investorType', 'accreditation', 'title', 'deployTarget'])
      }
   }, [])
   return (
      <Row style={{ display: isActive ? 'flex' : 'none' }}>
         <Column width={[1, 1 / 2]}>
            <SelectField
               {...register('investorType', {
                  required: 'This field is required',
               })}
               id="investorType"
               label="Investor Type"
               required
               error={errors.investorType}
               disabled={formStatus === 'sending'}
               options={[
                  { key: '', value: '' },
                  { key: 'Crypto Native', value: 'Crypto Native' },
                  { key: 'High Net Worth Individual', value: 'High Net Worth Individual' },
                  { key: 'Family Office', value: 'Family Office' },
                  { key: 'Financial Institution', value: 'Financial Institution' },
                  { key: 'Other', value: 'Other' },
               ]}
            />
         </Column>
         <Column width={[1, 1 / 2]}>
            <SelectField
               {...register('accreditation', {
                  required: 'This field is required',
               })}
               id="accreditation"
               label="Accreditation"
               required
               error={errors.accreditation}
               options={[
                  { key: '', value: '' },
                  { key: 'Accredited Investor', value: 'Accredited Investor' },
                  { key: 'Qualified Purchaser (Onshore)', value: 'Qualified Purchaser Onshore' },
                  { key: 'Qualified Purchaser (Offshore)', value: 'Qualified Purchaser Offshore' },
                  { key: 'Other', value: 'Other' },
               ]}
               disabled={formStatus === 'sending'}
            />
         </Column>
         <Column width={[1, 1 / 2]}>
            <InputField
               {...register('title')}
               id="title"
               label="Title"
               error={errors.title}
               disabled={formStatus === 'sending'}
            />
         </Column>
         <Column width={[1, 1 / 2]}>
            <SelectField
               {...register('deployTarget', {
                  required: 'This field is required',
               })}
               id="deployTarget"
               label="Deploy Target"
               required
               error={errors.deployTarget}
               options={[
                  { key: '', value: '' },
                  { key: '< $1,000,000', value: '< $1,000,000' },
                  { key: '$1,000,000 - $3,000,000', value: '$1,000,000 - $3,000,000' },
                  { key: '$3,000,000 - $5,000,000', value: '$3,000,000 - $5,000,000' },
                  { key: '$5,000,000 +', value: '$5,000,000 +' },
               ]}
               disabled={formStatus === 'sending'}
            />
         </Column>
      </Row>
   )
}
