import React from 'react'
import { graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import Layout from 'components/Layout'

import { LangContext } from 'context'

import ConnectCfBody from 'utils/connectCfBody'

const renderPageComponents = (components) => {
   if (components.length) {
      return components.map((component) => <ConnectCfBody key={component.id} {...component} />)
   }

   return <h2>This Page dont have any Components in Contentfull</h2>
}

const Page = ({ location, pageContext, data }) => {
   const { title, description, lang, pageName } = pageContext || {}
   const { page, header, footer } = data || {}

   return (
      <LangContext.Provider value={{ lang, path: location.pathname }}>
         <Layout
            lang={lang}
            pageTitle={title}
            pageName={pageName}
            pageDescription={description}
            location={location}
            header={header}
            footer={footer}
         >
            {!isEmpty(page) ? (
               renderPageComponents(page.body)
            ) : (
               <h2>Ups.. Something went wrong with Contentfull data</h2>
            )}
         </Layout>
      </LangContext.Provider>
   )
}

export const pageQuery = graphql`
   query pageQuery($id: String!, $lang: String!) {
      page: contentfulPage(id: { eq: $id }) {
         body {
            __typename
            ...LongTextData
            ...HeroData
            ...ImageContentData
            ...OurFirmData
            ...TeamData
            ...ContentListData
            ...SponsorsData
            # ...SummitFormData
            ...ContentTwoImagesData
            # ...ProjectsData
            ...ProjectsList
            # ...VideosData
            # ...PressTeamListData
            ...NotFound
            ...ContactUs
            ...PressHero
            ...PressResources
            ...MarkdownSectionQuery
         }
      }
      header: contentfulHeader(node_locale: { eq: $lang }) {
         searchPlaceholder
         headerLabel
         newsletterText {
            content: newsletterText
         }
         desktopNav {
            __typename
            ... on ContentfulLink {
               id
               name
               type
               url
               title
            }
         }
         mobileNav {
            id
            title
            type
            url
         }
         smallNav {
            id
            title
            type
            url
         }
      }
      footer: contentfulFooter(node_locale: { eq: $lang }) {
         menu {
            id
            title
            type
            url
         }
         smallMenu {
            id
            title
            type
            url
         }
         textContent {
            markdown: textContent
         }
         copyText {
            markdown: copyText
         }
         copyTitle
      }
   }
`

export default Page
