import React from 'react'
import styled from 'styled-components'

import iconDown from 'assets/svg/chevron-down.svg'

export default function CategoryFilter({ options, value, onChange, placeholder }) {
   return (
      <Wrapper>
         <label htmlFor="categoryFilter">{placeholder}</label>
         <select
            name="categoryFilter"
            id="categoryFilter"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            aria-label={placeholder}
         >
            <option value="ALL">{placeholder}</option>
            {options.map((opt) => (
               <option key={opt} value={opt}>
                  {opt}
               </option>
            ))}
         </select>
      </Wrapper>
   )
}

const Wrapper = styled.div`
   label {
      display: none;
   }

   select {
      width: 100%;
      height: 42px;
      padding: 0 16px;
      color: ${({ theme }) => theme.colors.textColor};
      background: ${({ theme }) => (theme.darkMode ? theme.colors.grey_6 : theme.colors.white)};
      border: ${({ theme }) =>
         theme.darkMode ? `2px solid ${theme.colors.grey_3}` : `2px solid ${theme.colors.grey_4}`};
      background-image: url(${iconDown});
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: calc(100% - 16px);
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.4px;
      outline: none;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      cursor: pointer;

      &::placeholder {
         font-size: 14px;
         color: ${({ theme }) => theme.colors.grey_3};
      }

      option {
         color: inherit;
         font-size: inherit;
         line-height: inherit;
      }
   }
`
