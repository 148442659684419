import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import posed from 'react-pose'

import { Row, Column } from 'components/Grid'
import trackEvent from 'utils/trackEvent'
import { encode } from 'utils/helpers'
import {
   InputField,
   SelectField,
   TextAreaField,
   NewsletterField,
   SubmitButton,
   InputFileField,
   renderConditionalLabel,
} from './FormField'
import FoundForm from './FoundForm'
import InvestmentForm from './InvestmentForm'
import { FORM_NAME, GENERAL_INQUIRY, FOUND_INQUIRY, INVESTMENT_INQUIRY } from './consts'

export default function MainForm() {
   const {
      register,
      handleSubmit,
      formState: { errors },
      watch,
      reset,
      unregister,
   } = useForm()

   const [formStatus, setFormStatus] = useState()
   const [activeForm, setActiveForm] = useState('')
   const [isAnimated, setIsAnimated] = useState(false)

   const aboutUsAnswer = watch('aboutUs')
   const prevAboutUsRef = useRef()
   const outreachType = watch('outreachType')
   const prevOutreachTypeRef = useRef()

   async function onSubmit(data) {
      setFormStatus('sending')
      if (data.newsletter) {
         await fetch('/.netlify/functions/newsletter', {
            method: 'POST',
            body: JSON.stringify({ email: data.email }),
         })
      }

      await fetch('/', {
         method: 'POST',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         body: encode({
            'form-name': FORM_NAME,
            ...data,
         }),
      })
         .then((res) => {
            if (res.status === 200) {
               reset()
               setFormStatus('success')
               trackEvent('Form Submitted', FORM_NAME)
            } else {
               throw new Error('Problem with API')
            }
         })
         .catch(() => {
            setFormStatus('error')
         })
   }

   useEffect(() => {
      setIsAnimated(true)
   }, [])

   useEffect(() => {
      if (prevAboutUsRef.current) {
         if (prevAboutUsRef.current !== aboutUsAnswer) {
            unregister(prevAboutUsRef.current)
            prevAboutUsRef.current = aboutUsAnswer
         }
      } else {
         prevAboutUsRef.current = aboutUsAnswer
      }
   }, [aboutUsAnswer])

   useEffect(() => {
      const prevValue = prevOutreachTypeRef.current
      if (prevValue) {
         if (prevValue !== outreachType) {
            prevOutreachTypeRef.current = outreachType
            setActiveForm(outreachType)
         }
      } else {
         prevOutreachTypeRef.current = outreachType
         if (outreachType) {
            setActiveForm(outreachType)
         }
      }
   }, [outreachType])

   return (
      <PosedForm
         onSubmit={handleSubmit(onSubmit)}
         encType="multipart/form-data"
         data-netlify="true"
         style={{ marginBottom: 0, overflow: 'hidden' }}
         name={FORM_NAME}
         data-netlify-honeypot="bot-field"
         method="POST"
         pose={isAnimated ? 'open' : 'closed'}
      >
         <input type="hidden" name="form-name" value={FORM_NAME} />
         <input type="hidden" name="stage" value="" />
         <input type="hidden" name="url" value="" />
         <input type="hidden" name="telegram" value="" />
         <input type="hidden" name="twitter" value="" />
         <input type="hidden" name="investorType" value="" />
         <input type="hidden" name="accreditation" value="" />
         <input type="hidden" name="title" value="" />
         <input type="hidden" name="deployTarget" value="" />
         <Row>
            <Column width={[1, 1 / 2]}>
               <PosedFormField pose>
                  <InputField
                     {...register('firstName', { required: 'This field is required' })}
                     id="firstName"
                     label="First Name"
                     required
                     error={errors.firstName}
                     disabled={formStatus === 'sending'}
                  />
               </PosedFormField>
            </Column>
            <Column width={[1, 1 / 2]}>
               <PosedFormField>
                  <InputField
                     {...register('lastName', { required: 'This field is required' })}
                     id="lastName"
                     label="Last Name"
                     required
                     error={errors.lastName}
                     disabled={formStatus === 'sending'}
                  />
               </PosedFormField>
            </Column>
            <Column width={[1, 1 / 2]}>
               <PosedFormField>
                  <InputField
                     {...register('companyName', {
                        required: 'This field is required',
                     })}
                     id="companyName"
                     label="Organization"
                     required
                     error={errors.companyName}
                     disabled={formStatus === 'sending'}
                  />
               </PosedFormField>
            </Column>
            <Column width={[1, 1 / 2]}>
               <PosedFormField>
                  <InputField
                     {...register('email', {
                        required: 'This field is required',
                        pattern: {
                           value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                           message: 'Invalid email address',
                        },
                     })}
                     id="email"
                     label="Email"
                     required
                     error={errors.email}
                     disabled={formStatus === 'sending'}
                  />
               </PosedFormField>
            </Column>
            <Column width={[1, 1, 1, 1 / 2]}>
               <PosedFormField>
                  <SelectField
                     {...register('outreachType', {
                        required: 'This field is required',
                     })}
                     id="outreachType"
                     label="Outreach Type"
                     required
                     error={errors.outreachType}
                     options={[
                        { key: '', value: '' },
                        { key: 'General Inquiry', value: GENERAL_INQUIRY },
                        { key: 'Seeking Fund Information', value: FOUND_INQUIRY },
                        { key: 'Pitch an idea for investment', value: INVESTMENT_INQUIRY },
                     ]}
                     disabled={formStatus === 'sending'}
                  />
               </PosedFormField>
            </Column>
            <Column width={[1, 1, 1, 1 / 2]}>
               <PosedFormField>
                  <SelectField
                     {...register('aboutUs')}
                     id="aboutUs"
                     label="How’d you hear about us?"
                     error={errors.aboutUs}
                     options={[
                        { key: '', value: '' },
                        { key: 'News', value: 'news' },
                        { key: 'Podcast', value: 'podcast' },
                        { key: 'Event', value: 'event' },
                        { key: 'Referral', value: 'referral' },
                        { key: 'Newsletter', value: 'newsletter' },
                     ]}
                     disabled={formStatus === 'sending'}
                  />
               </PosedFormField>
            </Column>
            {aboutUsAnswer && ['news', 'event', 'referral'].includes(aboutUsAnswer) && (
               <Column width={[1]}>
                  <PosedFormField>
                     <InputField
                        {...register(aboutUsAnswer, {
                           required: 'This field is required',
                        })}
                        id={aboutUsAnswer}
                        label={renderConditionalLabel(aboutUsAnswer)}
                        required
                        error={errors[aboutUsAnswer]}
                        disabled={formStatus === 'sending'}
                     />
                  </PosedFormField>
               </Column>
            )}
         </Row>
         {activeForm === FOUND_INQUIRY && (
            <FoundForm
               isActive={activeForm === FOUND_INQUIRY}
               register={register}
               unregister={unregister}
               formStatus={formStatus}
               errors={errors}
            />
         )}
         {activeForm === INVESTMENT_INQUIRY && (
            <InvestmentForm
               isActive={activeForm === INVESTMENT_INQUIRY}
               register={register}
               unregister={unregister}
               formStatus={formStatus}
               errors={errors}
            />
         )}
         <Row>
            <Column width={[1]} mb={[10, 20]}>
               <PosedFormField>
                  <TextAreaField
                     {...register('note', { required: 'This field is required' })}
                     id="note"
                     label="Leave us a note"
                     error={errors.note}
                     required
                     disabled={formStatus === 'sending'}
                  />
               </PosedFormField>
            </Column>
            {outreachType === INVESTMENT_INQUIRY && (
               <Column width={[1]} mb={[10, 20]}>
                  <InputFileField
                     {...register('attachment', {
                        validate: {
                           lessThan10MB: (files) => (files && files[0] ? files[0].size < 10000000 || 'Max 10MB' : true),
                           acceptedFormats: (files) =>
                              files && files[0]
                                 ? ['application/pdf', 'application/doc', 'application/msword'].includes(
                                      files[0].type
                                   ) || 'Only PDF or DOC file'
                                 : true,
                        },
                     })}
                     id="attachment"
                     error={errors.attachment}
                     actualValue={watch('attachment')}
                     disabled={formStatus === 'sending'}
                  />
               </Column>
            )}
            <Column width={[1]}>
               <PosedFormField>
                  <NewsletterField
                     {...register('newsletter')}
                     id="newsletter"
                     error={errors.newsletter}
                     disabled={formStatus === 'sending'}
                  />
               </PosedFormField>
            </Column>
            <Column width={[1]}>
               <PosedFormField>
                  <SubmitButton status={formStatus} />
               </PosedFormField>
            </Column>
         </Row>
      </PosedForm>
   )
}

const PosedForm = posed.form({
   open: {
      staggerChildren: 150,
      delayChildren: 250,
   },
   initialPose: 'closed',
})

const PosedFormField = posed.div({
   open: { opacity: 1, y: 0 },
   closed: { opacity: 0, y: 8 },
})
