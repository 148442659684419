import styled from 'styled-components'

import media from 'utils/media'

import { Column } from 'components/Grid'

const Content = styled(Column)`
   ${media.greaterThan('lg')`
    padding-top: 104px;
  `};

   h2 {
      margin-bottom: 26px;

      ${media.greaterThan('md')`
      margin-bottom: 31px;
    `};

      ${media.greaterThan('lg')`
      margin-bottom: 43px;
    `};
   }

   p {
      letter-spacing: -0.4px;

      ${media.greaterThan('md')`
      letter-spacing: -0.2px;
    `};
   }

   div > a {
      display: inline-block;
      margin-top: 26px;

      &:last-child {
         margin-bottom: 0;

         ${media.greaterThan('lg')`
        margin-top: 73px;
      `};
      }
   }
`

export default Content
