export default {
   languages: [
      {
         lang: 'en-US',
         label: 'English',
         disabled: false,
      },
      {
         lang: 'zh',
         label: '普通話',
         disabled: false,
      },
      {
         lang: 'ko',
         label: '한국어',
         disabled: false,
      },
      {
         lang: 'ja',
         label: '日本語',
         disabled: false,
      },
   ],
   projectCategory: {
      LAYER1: 'Layer 1',
      LAYER2: 'Layer 2',
      INFRASTRUCTURE: '인프라',
      IDENTITY: '신원',
      PRIVACY: '프라이버시',
      ENTERPRISE: '기업',
      MOBILE: '모바일',
   },
   socials: {
      facebook: '페이스 북',
      twitter: '지저귀다',
      linkedin: '링크드 인',
      email: '이메일',
      reddit: 'reddit',
      telegram: '전보',
   },
   hamburgerMenu: {
      label: '메뉴',
   },
}
