import styled from 'styled-components'
import media from 'utils/media'

const Section = styled.section`
   padding: 26px 0;

   ${media.greaterThan('md')`
      padding: 48px 0 81px;
  `};

   .title {
      h2 {
         margin-bottom: 27px;
      }

      p {
         line-height: 1.75;

         ${media.greaterThan('md')`
            font-size: 18px;
            line-height: 1.56;
         `};
      }
   }
`

export default Section
